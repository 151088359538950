import React, { ReactElement, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../store/hooks';
import { getMySeriesList } from '../store/reducers/entities/series/seriesSlice';

const MyTours = (): ReactElement => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getMySeriesList());
    }, []);

    return <>
        <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
            <div className="col-12 col-lg-10 col-xl-9">
                <div className="page-content position-relative">
                    <button title="Go to Dashboard" className="closeAndGoBack" onClick={e => navigate('/dashboard')}>
                        <i className="fal fa-times" />
                    </button>
                    <Outlet />
                </div>
            </div>
        </div>
    </>
}

export default MyTours;