import { combineReducers } from "@reduxjs/toolkit";
import featureSlice from "./feature/featureSlice";
import seriesSlice from "./series/seriesSlice";

export interface iTabPanel{
    isComplete: boolean;
    url: string;
}

export default combineReducers({
    series: seriesSlice,
    feature: featureSlice,
    // feature
    // asset
});