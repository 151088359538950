const validateImageFile = async (file: File, {width = 0, height = 0}) => {
    return new Promise(async (resolve, reject) => {
        let img = new Image();
        if (file && file.name) {
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                let isValid = false;
                if (width !== 0 && height !== 0) {
                    isValid = img.width === width && img.height === height
                } else if (width !== 0) {
                    isValid = img.width === width
                } else if (height !== 0) {
                    isValid = img.height === height
                }
                resolve(isValid);
            };
            img.onerror = reject;
        } else {
            resolve(false);
        }
    })
};


const passwordStrengthValidator = async (element: HTMLInputElement) => {
    const value = element.value;
    const specialCharacters = ['_', '#', '%', '*', '@'];
    const prohibitedCharacters = ['$', '&', '=', '!'];
    const minSize = 7;
    const maxSize = 16;

    let progress = 0;
    let hasValidLength = value.length >= minSize && value.length <= maxSize,
        noProhibitedCharacters = !new RegExp("[" + prohibitedCharacters.join('') + "]").test(value),
        hasSpecialCharacters = new RegExp("[" + specialCharacters.join('') + "]").test(value),
        hasUpperCase = value.replace(/[^A-Z]/g, "").length > 0,
        hasNumbers = /\d/.test(value);

    if (hasValidLength) {
        element.classList.remove('error-length');
        progress += 20;
    } else {
        element.classList.add('error-length');
    }
    if (noProhibitedCharacters) {
        element.classList.remove('error-prohibited-characters');
        progress += 20;
    } else {
        element.classList.add('error-prohibited-characters');
    }
    if (hasSpecialCharacters) {
        element.classList.remove('error-special-characters');
        progress += 20;
    } else {
        element.classList.add('error-special-characters');
    }
    if (hasUpperCase) {
        element.classList.remove('error-uppercase');
        progress += 20;
    } else {
        element.classList.add('error-uppercase');
    }
    if (hasNumbers) {
        element.classList.remove('error-number');
        progress += 20;
    } else {
        element.classList.add('error-number');
    }
    const progressbar = element.getAttribute('data-progressbar') !== null ? document.querySelector(element.getAttribute('data-progressbar') as string) : false;
    if (progressbar) {
        (progressbar as HTMLElement).style.width = progress + '%';
    }
    const isValid = hasValidLength && noProhibitedCharacters && hasSpecialCharacters && hasUpperCase && hasNumbers;
    if (!isValid) {
        element.classList.add('error');
    } else {
        element.classList.remove('error');
    }
    return isValid;
}

export {
    passwordStrengthValidator,
    validateImageFile
}
