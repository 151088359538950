import React, {ReactElement, useEffect, useState} from 'react';
import parse from 'html-react-parser';
import nProgress from 'nprogress';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getFeatureById } from '../../../store/reducers/entities/feature/featureSlice';
import {Outlet, useParams} from 'react-router-dom';
import PanelsNavigation from '../PanelNavigation/PanelsNavigation';

type Props = {
}

const FeatureDetail =  (props: Props): ReactElement => {

    const panelNames = ['Draw the path','Image', 'Tags', 'Script'];

    const { featureId } = useParams();
    const dispatch = useAppDispatch();
    const stateLoading = useAppSelector(state => state.entities.feature.loading);
    const feature = useAppSelector(state => state.entities.feature.selectedFeature)
    const [featureLoaded, setFeatureLoaded] = useState(false);


    useEffect(() => {
        if(!featureId) return;
        nProgress.start();
        dispatch(getFeatureById(featureId!, 'Failed to load feature'));
    }, [featureId]);
    
    useEffect(() => {
        if(!stateLoading) nProgress.done();
    }, [stateLoading])

    useEffect(() => {
        if(feature && feature._id){
            setFeatureLoaded(true)
        }
    }, [feature])



    if(!featureId) return <><i className="fa fa-spin fa-spinner" /></>
    return <>
        <div className="accordion-tab-content p-0">
            <PanelsNavigation panelNames={panelNames} modelType={'feature'} />
            {
                featureLoaded && <div className="shadow p-4 mt-4 rounded">
                    <Outlet />
                </div>
            }
        </div>
    </>
}

export default FeatureDetail