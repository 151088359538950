import React, {ReactElement, useState, useEffect, useCallback, useRef} from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { loadUser } from '../store/reducers/auth/authSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import NProgress from 'nprogress';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import globalConfig from "../global.config";
import {IResolveParams, LoginSocialFacebook, TypeCrossFunction} from "reactjs-social-login";

export interface iSocialAuth {
    SocialProvider: 'facebook' | 'apple' | 'google' | 'linkedin'
    SocialProviderUserID: string
    Email: string
    FirstName?: string
    LastName?: string
    Name: string
}

const Login = (): ReactElement => {
    const facebookRef = useRef<TypeCrossFunction>(null!)
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const stateLoading = useAppSelector((state) => state.auth.loading);
    const userSelector = useAppSelector((state) => state.auth.user);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        welcomeUser();
    },[]);



    useEffect(() => {
        const token = Cookies.get('access_token');
        if(userSelector && userSelector._id && token && token.length > 50 && !stateLoading){
            /* todo: if profile is incomplete navigate to profile page instead of dashboard. */
            navigate('/dashboard');
        }
    }, [userSelector, stateLoading]);

    const welcomeUser = () => {
        /* todo: magic string */
        dispatch(loadUser('Welcome back!'));
    }

    const formik = useFormik({
        initialValues: {
            Email: '',
            Password: ''
        },
        validationSchema: Yup.object({
            Email: Yup.string().email().required('Required'),
            Password: Yup.string().required('Required').min(6),
        }),
        onSubmit: async values => {
            setLoading(true);
            NProgress.start()
            try {
                const response = await axios.post('/auth/login', values);
                if (response && response.data && response.data.access_token) {
                    Cookies.set('access_token', response.data.access_token, { expires: 7 });
                    welcomeUser();
                }
            } catch (e: any) {
                toast.error("Invalid credentials");
            } finally{
                setLoading(false);
                NProgress.done();
            }
        },
        validateOnBlur: true
    });

    const socialAuthRequest = async (data: iSocialAuth) => {
        setLoading(true);
        NProgress.start()
        try {
            const response = await axios.post('/users/social-auth', data);
            if (response && response.data && response.data.access_token) {
                Cookies.set('access_token', response.data.access_token, { expires: 7 });
                welcomeUser();
            }
        } catch (e: any) {
            toast.error(e);
        } finally{
            setLoading(false);
            NProgress.done();
        }
    }

    const onLoginStart = useCallback(() => {
        setLoading(true);
        NProgress.start()
    }, [])

    return <>
        <div className="row animated fadeInUp no-gutters min-vh-100 align-items-center justify-content-center page-login">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 align-items-center">
                <div className="row full-page-row no-gutters align-items-center">
                    <div className="col-12 col-md-6">
                        <div className="bg-white page-content">
                            <h1 className="text-900 text-left text-uppercase theme-text-gradient d-inline-block title-lg m-0">
                                <span>ADD <span className="fonts-secondary text-600">+</span></span>
                                <span>Your </span>
                                <span>Tour</span>
                            </h1>
                        </div>
                    </div>
                    <div className="page-form-wrap col">
                        <div className="page-form">
                            <div className="login-social-options">
                                {/*<AppleLogin />*/}
                                {/*<FacebookLoginButton apolloClient={this.props.apolloClient} />*/}

                                <LoginSocialFacebook
                                    ref={facebookRef}
                                    appId={'180031860012956'}
                                    fieldsProfile={
                                        'id,first_name,last_name,name,email,picture'
                                    }
                                    onLoginStart={onLoginStart}
                                    onResolve={({ provider, data }: IResolveParams) => {
                                        console.log(data);
                                        if(data && data.email) {
                                            socialAuthRequest({
                                                SocialProvider: 'facebook',
                                                SocialProviderUserID: data.id,
                                                Email: data.email,
                                                Name: data.name ? data.name : '',
                                                FirstName: data.first_name ? data.first_name : '',
                                                LastName: data.last_name ? data.last_name : '',
                                            })
                                        } else {
                                            toast.error('Unable to authenticate, Email is required.')
                                        }
                                    }}
                                    onReject={(err) => {
                                        toast.error(err);
                                        setLoading(false);
                                        NProgress.done()
                                    }}
                                >
                                    <button className="btn-facebook btn btn-block">
                                        <i className="fab fa-facebook-f" />
                                        Login with Facebook
                                    </button>
                                </LoginSocialFacebook>

                                {/*<LinkedInLogin />*/}
                            </div>
                            <form  noValidate className={stateLoading || loading ? 'loading' : ''} onSubmit={formik.handleSubmit}>
                                <div className="form-options-separator">Or Login with</div>
                                <div className="form-group with-icon floating-label-group">
                                    <input type="email"
                                        className={formik.touched.Email && formik.errors.Email ? "error form-control" : "form-control"}
                                        id="Email"
                                        placeholder="Email"
                                        autoComplete="Email"
                                        {...formik.getFieldProps('Email')}
                                    />
                                    <div className="after-control">
                                        <label className="floating-label" htmlFor="Email">Email</label>
                                        <span className="focus-effect" />
                                        <div className="popover bs-popover-top">
                                            <div className="arrow">
                                            </div>
                                            <div className="popover-body">
                                                <span className="no-wrap">Email is required to login</span>
                                            </div>
                                        </div>
                                        <span className="form-control-icon fal fa-envelope" />
                                    </div>
                                </div>

                                <div className="form-group custom-popover top with-icon floating-label-group">
                                    <input type="password"
                                        className={formik.touched.Password && formik.errors.Password ? "error form-control" : "form-control"}
                                        id="Password"
                                        autoComplete="Password"
                                        placeholder="Password"
                                        {...formik.getFieldProps('Password')}
                                    />
                                    <div className="after-control">
                                        <label className="floating-label" htmlFor="password">Password</label>
                                        <span className="focus-effect" />
                                        <div className="popover bs-popover-top">
                                            <div className="arrow" />
                                            <div className="popover-body">
                                                <span className="no-wrap">Password is required to login</span>
                                            </div>
                                        </div>
                                        <span className="form-control-icon fal fa-lock" />
                                    </div>
                                </div>

                                <div className="form-group floating-label-group">
                                    <button type="submit" className="btn btn-md text-uppercase btn-block btn-primary" title="Login">
                                        <span className="btn-text">Login</span>
                                        <i className="btn-icon far fa-spinner-third fa-spin" />
                                    </button>
                                </div>

                                <div className="clearfix fonts-secondary">
                                    <Link className="color-light text-borderline float-left" to="/register">
                                        Sign Up
                                    </Link>
                                    <Link className="color-light text-borderline float-right" to="/forgot-password">
                                        Forgot Password ?
                                    </Link>
                                </div>
                            </form>
                            <Link id="authSuccess" className="pseudo-link" to="/dashboard" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Login;
