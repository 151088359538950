import React, {ReactElement, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import PanelsNavigation from '../PanelNavigation/PanelsNavigation';

type props = {
}

const SeriesFinalize = (props: props): ReactElement => {
    const panelNames = ['Polygon Area', 'Summary', 'Teaser'];

    
    return <div className="accordion-tab-content p-0">
        <PanelsNavigation panelNames={panelNames} modelType={'series'} panelsAttribute={'panelsListFinal'}  />
        <div className="shadow p-4 mt-4 rounded">
            <Outlet />
        </div>
    </div>
}

export default SeriesFinalize;