
import React, { ReactElement, useEffect, useState } from 'react';
import ReactTags from 'react-tag-autocomplete';

import tagsJson from '../../tags.json';
import eraJson from '../../eras.json';
import { useAppDispatch } from '../../store/hooks';
import { updateSeries } from '../../store/reducers/entities/series/seriesSlice';
import {updateFeature} from "../../store/reducers/entities/feature/featureSlice";

type Tag = {
	id: string;
	name: string
};

interface props {
	tags: string;
	eras: string;
	modelId: string;
	modelType: 'series' | 'feature';
}


const TagsManager = (props: props): ReactElement => {

	const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(false);
    const [tagSuggestions, setTagSuggestions] = useState<Tag[]>([]);
    const [eraSuggestions, setEraSuggestions] = useState<Tag[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [eras, setEras] = useState<Tag[]>([]);
	const [updated, setUpdated] = useState(false);

    useEffect(() => {
        populateSelectedValues();
    },  [props]);

    useEffect(() => {
        const tags = (tagsJson.tags).map((tag: string) => {
			return {
				id: tag,
				name: tag
			}
		});
		const eras = eraJson.Eras.map((era: string) => {
			return {
				id: era,
				name: era
			}
		})
        setTagSuggestions(tags);
        setEraSuggestions(eras)
    }, [])

	useEffect(() => {
		if(!updated) return;
		setUpdated(false);
		const erasArray = eras.map(era => {return era.name});
		const TagArray = tags.map(tag => {return tag.name});
		let data: any = {};
		if(erasArray.toString() !== props.eras){
			data.Era = erasArray.toString();
		}
		if(TagArray.toString() !== props.tags){
			data.Tag = TagArray.toString();
		}
		if(props.modelType === 'series'){
			dispatch(updateSeries(props.modelId, data, 'updated!', 'Failed'));
		} else {
			dispatch(updateFeature(props.modelId, data, 'updated!', 'Failed'));
		}
	}, [updated])

    const populateSelectedValues =  () => {
        let allTags = props.tags;
        let allEras = props.eras;
		if(allTags){
			let temp = allTags.split(',').map((tag) => {
				return {
					id: tag,
					name: tag
				}
			});
            setTags(temp);
		}
		if(allEras){
			let temp = allEras.split(',').map((era) => {
				return {
					id: era,
					name: era
				}
			});
            setEras(temp);
		}
	}


    const onDeleteTag = async (i: number) => {
		const t = tags!.slice(0)
		t.splice(i, 1)
		setTags(t);
		setUpdated(true);
	}

	const onDeleteEra = async (i: number) => {
		const t = eras!.slice(0)
		t.splice(i, 1)
		setEras(t);
		setUpdated(true);
	}

	const onAdditionTag = async (tag: Tag) => {
		if(!tag.id){
			tag = {
				id: tag.name,
				name: tag.name,
			}
		}
        setTags([...tags!, tag]);
		setUpdated(true);
	}


	const onAdditionEra = async (era: Tag) => {
		if(!era.id){
			era = {
				id: era.name,
				name: era.name,
			}
		}
        setEras([...eras!, era]);
		setUpdated(true);
	}


    return <>
        <p>Please set and select tags and eras for this tour. This will help travellers to find this tour.</p>
        <div className="mb-4">
            <div className="mb-2">
                <strong>Tags</strong>
            </div>
            <ReactTags
                allowNew={true}
                placeholderText="Search..."
                tags={tags}
                suggestions={tagSuggestions}
                onDelete={onDeleteTag}
                onAddition={onAdditionTag} />
            <span className="small color-light">Examples: history, Kenya, Freedom</span>
        </div>
		<div className="mb-4">
            <div className="mb-2">
                <strong>Eras</strong>
            </div>
            <ReactTags
                allowNew={true}
                placeholderText="Search..."
                tags={eras}
                suggestions={eraSuggestions}
                onDelete={onDeleteEra}
                onAddition={onAdditionEra} />
            <span className="small color-light">Examples: 17th Century, 1850-1860, Revolt Era, Ottoman Empire</span>
        </div>

    </>
};

export default TagsManager;