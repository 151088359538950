import React, {ReactElement} from 'react';
import NextButton from '../PanelNavigation/NextButton';
import WikiArticles from '../WikiArticles';

interface props{

}

const SeriesWiki = (props: props): ReactElement => {

    return <>
        <NextButton label="Next" />
        <h3 className="h4">Wiki Connection</h3>
        <WikiArticles modelType="series" />
    </>
};

export default SeriesWiki;