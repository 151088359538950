import React, { ReactElement, useEffect, useState } from 'react';
import globalConfig from '../../global.config';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateSeries } from '../../store/reducers/entities/series/seriesSlice';


interface WikiPage {
    pageid: number;
    ns: number;
    title: string;
    lat: number;
    lon: number;
    dist: number;
}

interface WikiResponse {
    query: Record<'geosearch', WikiPage[]>
}

interface props {
    modelType: 'feature' | 'series';
}

const WikiArticles = (props: props): ReactElement => {

    const dispatch = useAppDispatch();
    const series = useAppSelector(state => state.entities.series.selectedSeriesData);
    const [wikiID, setWikiID] = useState<number | undefined>(series?.WikiID);
    const [wikiPages, setWikiPages] = useState<WikiPage[]>();
    const [updated, setUpdated] = useState(false);


    useEffect(() => {
        if (series && series.Lat && series.Lng && series.FoundRelatedWikiArticles) {
            getWikiArticles();
        }
    }, [series]);

    useEffect(() => {
        if (!updated) return;
        setUpdated(false)
        if (props.modelType === 'series') {
            dispatch(updateSeries(series!._id, {
                WikiID: wikiID
            }, 'Saved!', 'Failed to connect wiki page!'));
        }
    }, [wikiID]);

    const getWikiArticles = async (): Promise<void> => {

            const limit = globalConfig.variables.wikipediaArticlesLimit;
            let wikiQueryUrl = globalConfig.externalEndpoints.wikipedia.getPages + series!.Lat + '%7C' + series!.Lng + '&gslimit=' + limit;
            fetch(wikiQueryUrl)
                .then(response => response.json())
                .then(async (data: WikiResponse) => {
                    let wikiList = data.query ? data.query.geosearch : [];
                    wikiList = wikiList.filter(p => {
                        if (!(p.title.toLowerCase().startsWith('list of') || p.title.toLowerCase().startsWith('national register'))) {
                            return p;
                        }
                    })
                    setWikiPages(wikiList);
                    if (wikiList.length === 0) { return }
                    setTimeout(() => {
                        const parent = document.getElementById('wiki-pages');
                        if (parent) {
                            const radioToCheck = parent.querySelector('input[value="' + wikiID + '"]') as HTMLInputElement;
                            if (radioToCheck && !radioToCheck.checked) {
                                radioToCheck.checked = true;
                            }
                        }
                    }, 1000);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
    }

    const articleSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const parent = document.getElementById("wiki-pages");
        const selected = parent?.querySelector('input[name="Series_wikiID"]:checked') as HTMLInputElement;
        if (parent && selected) {
            const selectedPage = selected.value;
            setUpdated(true);
            setWikiID(parseInt(selectedPage));
        }
    }

    return <>
        <div id="wiki-pages-wrap">
            <div id="wiki-pages" className="wiki-pages-list">
                <div className="mb-3 fonts-secondary">
                    {wikiPages && wikiPages.length ? "Please select one of the following wikipedia articles that is more about this tour." : "No Wiki connections found with respect to your selected central point."}
                </div>
                <div className="radio-control-list">
                    {wikiPages && wikiPages.map((page, i) => {
                        return (
                            <div key={"wiki-page-" + i}
                                className="form-group floating-label-group custom-radio-control">
                                <input type="radio"
                                    value={page.pageid}
                                    onChange={articleSelected}
                                    className="form-control"
                                    name="Series_wikiID"
                                    id={"wiki-page-" + i}
                                    required />
                                <div className="after-control">
                                    <span className="focus-effect" />
                                    <label htmlFor={"wiki-page-" + i}>
                                        {page.title} - <a target="_blank" title="link opens in a new tab"
                                            className="small" rel="noopener noreferrer"
                                            href={"http://en.wikipedia.org/?curid=" + page.pageid}>Open
                                            article</a>
                                    </label>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    </>
};

export default WikiArticles;