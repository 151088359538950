import nProgress from 'nprogress';
import React, { MouseEventHandler, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createNewFeature } from '../../../store/reducers/entities/feature/featureSlice';
import { getMySeriesById } from '../../../store/reducers/entities/series/seriesSlice';



const CreateNewFeature = (): ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const seriesId = useAppSelector(state => state.entities.series.selectedSeriesData?._id);
    const seriesPanels = useAppSelector(state => state.entities.series.panelsList);
    const [canAddFeatures, setCanAddFeatures] = useState(false);
    const [title, setTitle] = useState('');
    const [hasMap, setHasMap] = useState(true);
    const stateLoading = useAppSelector(state => state.entities.feature.loading);
    const [updated, setUpdated] = useState(false)

    useEffect(() => {
        if(!stateLoading) {
            nProgress.done();
            if(updated) {
                dispatch(getMySeriesById(seriesId!))
                navigate('/tours/' + seriesId!);
            }
        }
    }, [stateLoading])
    useEffect(() => {
        if( seriesPanels[0].isComplete &&  seriesPanels[1].isComplete &&  seriesPanels[2].isComplete ) setCanAddFeatures(true);
        else setCanAddFeatures(false);
    }, [seriesPanels]);

    const create = () => {
        if (title !== '' && title.length > 3) {
            nProgress.start();
            dispatch(createNewFeature({ Name: title, HasMap: hasMap, Series: seriesId! }, 'Failed to add new feature'));
            setUpdated(true);
        } else {
            /* todo: magic string */
            toast.error("Please enter a valid title.");
        }
    }

    if(!canAddFeatures) return <>
        <hr />
        <h6>Please complete tour setting</h6>
        <p>Features can only be added once tour setting has been completed.</p>
        <button className="btn btn-outline-primary" onClick={e=> navigate('/tours/' + seriesId! + '/setting')}>Back to setting</button>
    </>;

    return <>
         <div className="form-group floating-label-group mt-0">
                <input type="text"
                    className="form-control color-dark"
                    name="Name"
                    id="Name"
                    placeholder="Name"
                    onChange={e => setTitle(e.currentTarget.value)}
                    required />
                <div className="after-control">
                    <label className="floating-label" htmlFor="Name">Feature Name</label>
                    <span className="focus-effect" />
                </div>
            </div>
            <div className="custom-checkbox-control small">
                <label htmlFor="HasMap">
                    <input id="HasMap" type="checkbox" name="HasMap" defaultChecked={hasMap} onChange={e => setHasMap(e.target.checked)} />
                    <span>{hasMap ? <>This feature will have map.</> : <>This feature is a filler i.e. this will not have a map.</>}</span>
                </label>
            </div>

            <div className="text-right mb-4">
                <button onClick={create} type="submit" className="btn btn-outline-primary">Create Feature</button>
                <button onClick={e => navigate('/tours/' + seriesId!)} type="submit" className="btn btn-outline-danger ml-2">Cancel</button>
            </div>
    </>
}

export default CreateNewFeature;