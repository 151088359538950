import React, {ReactElement, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import profileIcon from "../svg-icons/profile.svg";
import toursIcon from "../svg-icons/review.svg";
import newTourIcon from "../svg-icons/new-tour.svg";
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {getMySeriesList} from "../store/reducers/entities/series/seriesSlice";


/*todo: create interface for dashboard FC*/
const Dashboard = (props: any): ReactElement => {

    const dispatch = useAppDispatch();
    const userSelector = useAppSelector(state => state.auth.user);
    const seriesList = useAppSelector(state => state.entities.series.list);

    useEffect(() => {
        dispatch(getMySeriesList());
    }, []);


    return <>
        <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
            <div className="col-12 col-xl-10">
                <div className="dashboard-cards">
                    <h1>Welcome!</h1>
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                            <div className="dashboard-card">
                                <div className="dashboard-card-inner">
                                    <ReactSVG src={profileIcon} />
                                    <strong>Your Profile</strong>
                                    {
                                        userSelector && userSelector.Username && userSelector.Location && userSelector.Mobile  && userSelector.Bio  && userSelector.LanguageID && userSelector.Photo ? <>
                                            <span className="dashboard-card-badge success"><i className="far fa-check-circle" /> Complete</span>
                                        </> : <>
                                            <span className="dashboard-card-badge danger"><i className="far fa-exclamation-circle" /> Please complete your profile.</span>
                                        </>
                                    }
                                </div>
                                <Link to="/profile">Profile</Link>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                            <div className="dashboard-card">
                                <div className="dashboard-card-inner">
                                    <ReactSVG src={newTourIcon} />
                                    <strong>Add A Tour</strong>
                                </div>
                                <Link to="/tours/new">Add A Tour</Link>
                            </div>
                        </div>
                        {
                            seriesList && seriesList.length ? <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                                <div className="dashboard-card">
                                    <div className="dashboard-card-inner">
                                        <ReactSVG src={toursIcon} />
                                        <strong>Edit Tours</strong>
                                        <span className="dashboard-card-badge">You have {seriesList.length} Tour{seriesList.length > 1 && "s"}</span>
                                    </div>
                                    <Link to="/tours">Edit Tours</Link>
                                </div>
                            </div> : ''
                        }
                    </div>
                    <div className="row justify-content-center mt-5">
                        <Link to="/logout" className="btn btn-text text-white"><i className="fa fa-power-off mr-2" />Logout</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Dashboard;