import React, {ReactElement} from 'react';
import NextButton from "../PanelNavigation/NextButton";
import TourImageManager from "../TourImageManager";
import globalConfig from "../../../global.config";
import {useAppSelector} from "../../../store/hooks";

interface props{

}

const FeatureImage = (props: props): ReactElement => {

    const feature = useAppSelector(state => state.entities.feature.selectedFeature)


    if (!feature) return <></>;
    return <>
        <NextButton label="Next" />
        <TourImageManager
            modelId={feature._id}
            modelType={'feature'}
            uploadingUrl={globalConfig.endpoints.feature.updateBoxImg.url + '/' + feature._id} />
    </>
};

export default FeatureImage;