import React, {ReactElement, useEffect, useState} from 'react';
import { useAppSelector } from '../../../store/hooks';
import MapBox from '../MapBox';
import NextButton from '../PanelNavigation/NextButton';
import {useNavigate, useParams} from "react-router-dom";
import AssetText from "../AssetText";
import {toast} from "react-toastify";

interface props{

}

const SeriesTeaser = (props: props): ReactElement => {

    const { seriesId } = useParams();
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState(false);

    const finalPanels = useAppSelector(state => state.entities.series.panelsListFinal);

    useEffect(() => {
        if(finalPanels[2].isComplete){
            setIsValid(true);
        }
    }, [finalPanels])

    const validateScript = () => {
        if(isValid){
            navigate('/tours/'+ seriesId)
        } else {
            /* magic string */
            toast.error('Please write a teaser for this tour.');
        }
    }

    return <div>
        <header>
            <button onClick={validateScript} className={"btn btn-wide btn-secondary ml-auto float-right"}>Save</button>
            <h3 className="h4">Teaser</h3>
        </header>
        {
            seriesId && seriesId.length > 0 ? <AssetText modelType={'series'} modelId={seriesId} attribute={'Teaser'} /> : ''
        }
    </div>
};

export default SeriesTeaser;