
import React, {ReactElement} from 'react';
import {useAppSelector} from "../../../store/hooks";
import TagsManager from "../TagsManager";
import NextButton from "../PanelNavigation/NextButton";

interface props{

}

const FeatureTags = (props: props): ReactElement => {

    const feature = useAppSelector(state => state.entities.feature.selectedFeature)

    if(!feature) <></>;


    return <>
        <header>
            <NextButton label="Next" />
            <h3 className="h4">Tags</h3>
        </header>
        <TagsManager modelId={feature!._id} modelType={'feature'} tags={feature!.Tag ? feature!.Tag : ''} eras={feature!.Era ? feature!.Era : ''}  />
    </>
};

export default FeatureTags;