import React from "react";


const LanguageSelect = (props: any) => {
    return (
        <select {...props}>
            <option value="">Language</option>
            <option
                title="English (US)"
                value="1"
                data-e-value="en-US"
            >
                English (US) (en-US)
            </option>
            <option
                title="Afrikaans"
                value=""
                data-e-value="af"
                disabled
            >
                Afrikaans (af)
            </option>
            <option title="Arabic" value="8" data-e-value="ar">
                عربي (ar)
            </option>
            <option
                title="Azerbaijani"
                value=""
                data-e-value="az"
                disabled
            >
                Azərbaycanca (az)
            </option>
            <option
                title="Bambara"
                value=""
                data-e-value="bm"
                disabled
            >
                Bamanankan (bm)
            </option>
            <option
                title="Bengali (Bangladesh)"
                value=""
                data-e-value="bn-BD"
                disabled
            >
                বাংলা (বাংলাদেশ) (bn-BD)
            </option>
            <option
                title="Bengali (India)"
                value=""
                data-e-value="bn-IN"
                disabled
            >
                বাংলা (ভারত) (bn-IN)
            </option>
            <option
                title="Catalan"
                value=""
                data-e-value="ca"
                disabled
            >
                Català (ca)
            </option>
            <option
                title="Czech"
                value=""
                data-e-value="cs"
                disabled
            >
                Čeština (cs)
            </option>
            <option
                title="Chinese (Simplified)"
                value="9"
                data-e-value="zh-CN"
            >
                中文 (简体) (zh-CN)
            </option>
            <option
                title="Chinese (Traditional)"
                value=""
                data-e-value="zh-TW"
                disabled
            >
                正體中文 (繁體) (zh-TW)
            </option>
            <option title="Danish" value="14" data-e-value="da">
                Dansk (da)
            </option>
            <option title="German" value="10" data-e-value="de">
                Deutsch (de)
            </option>
            <option
                title="Ewe"
                value=""
                data-e-value="ee"
                disabled
            >
                Eʋe (ee)
            </option>
            <option
                title="Greek"
                value=""
                data-e-value="el"
                disabled
            >
                Ελληνικά (el)
            </option>
            <option title="Spanish" value="5" data-e-value="es">
                Español (es)
            </option>
            <option
                title="Persian"
                value=""
                data-e-value="fa"
                disabled
            >
                فارسی (fa)
            </option>
            <option
                title="Fulah"
                value=""
                data-e-value="ff"
                disabled
            >
                Pulaar-Fulfulde (ff)
            </option>
            <option
                title="Finnish"
                value=""
                data-e-value="fi"
                disabled
            >
                suomi (fi)
            </option>
            <option title="French" value="6" data-e-value="fr">
                Français (fr)
            </option>
            <option
                title="French"
                value="7"
                data-e-value="fr-ca"
            >
                Français Canadien (fr-ca)
            </option>
            <option
                title="Frisian"
                value=""
                data-e-value="fy-NL"
                disabled
            >
                Frysk (fy-NL)
            </option>
            <option
                title="Irish"
                value=""
                data-e-value="ga-IE"
                disabled
            >
                Gaeilge (ga-IE)
            </option>
            <option
                title="Hausa"
                value=""
                data-e-value="ha"
                disabled
            >
                Hausa (ha)
            </option>
            <option
                title="Hebrew"
                value=""
                data-e-value="he"
                disabled
            >
                עברית (he)
            </option>
            <option
                title="Hindi (India)"
                value="3"
                data-e-value="hi-IN"
            >
                हिन्दी (भारत) (hi-IN)
            </option>
            <option
                title="Croatian"
                value=""
                data-e-value="hr"
                disabled
            >
                Hrvatski (hr)
            </option>
            <option
                title="Hungarian"
                value="22"
                data-e-value="hu"
            >
                magyar (hu)
            </option>
            <option
                title="Indonesian"
                value=""
                data-e-value="id"
                disabled
            >
                Bahasa Indonesia (id)
            </option>
            <option
                title="Igbo"
                value=""
                data-e-value="ig"
                disabled
            >
                Igbo (ig)
            </option>
            <option
                title="Italian"
                value="11"
                data-e-value="it"
            >
                Italiano (it)
            </option>
            <option
                title="Japanese"
                value="12"
                data-e-value="ja"
            >
                日本語 (ja)
            </option>
            <option
                title="Georgian"
                value=""
                data-e-value="ka"
                disabled
            >
                ქართული (ka)
            </option>
            <option
                title="Kabyle"
                value=""
                data-e-value="kab"
                disabled
            >
                Taqbaylit (kab)
            </option>
            <option title="Korean" value="13" data-e-value="ko">
                한국어 (ko)
            </option>
            <option
                title="Lingala"
                value=""
                data-e-value="ln"
                disabled
            >
                Lingála (ln)
            </option>
            <option
                title="Malagasy"
                value=""
                data-e-value="mg"
                disabled
            >
                Malagasy (mg)
            </option>
            <option
                title="Malayalam"
                value=""
                data-e-value="ml"
                disabled
            >
                മലയാളം (ml)
            </option>
            <option
                title="Malay"
                value=""
                data-e-value="ms"
                disabled
            >
                Melayu (ms)
            </option>
            <option title="Norsk" value="21" data-e-value="no">
                Norsk (no)
            </option>
            <option
                title="Burmese"
                value=""
                data-e-value="my"
                disabled
            >
                မြန်မာဘာသာ (my)
            </option>
            <option title="Dutch" value="4" data-e-value="nl">
                Nederlands (nl)
            </option>
            <option title="Polish" value="20" data-e-value="pl">
                Polski (pl)
            </option>
            <option
                title="Portuguese (Brazilian)"
                value=""
                data-e-value="pt-BR"
                disabled
            >
                Português (do Brasil) (pt-BR)
            </option>
            <option
                title="Portuguese (Portugal)"
                value="15"
                data-e-value="pt-PT"
            >
                Português (Europeu) (pt-PT)
            </option>
            <option
                title="Romanian"
                value="19"
                data-e-value="ro"
            >
                Română (ro)
            </option>
            <option
                title="Russian"
                value="16"
                data-e-value="ru"
            >
                Русский (ru)
            </option>
            <option
                title="Songhai"
                value=""
                data-e-value="son"
                disabled
            >
                Soŋay (son)
            </option>
            <option
                title="Albanian"
                value=""
                data-e-value="sq"
                disabled
            >
                Shqip (sq)
            </option>
            <option
                title="Serbian"
                value=""
                data-e-value="sr"
                disabled
            >
                Српски (sr)
            </option>
            <option
                title="Serbian"
                value=""
                data-e-value="sr-Latn"
                disabled
            >
                Srpski (sr-Latn)
            </option>
            <option
                title="Swedish"
                value="18"
                data-e-value="sv-SE"
            >
                Svenska (sv-SE)
            </option>
            <option
                title="Swahili"
                value=""
                data-e-value="sw"
                disabled
            >
                Kiswahili (sw)
            </option>
            <option
                title="Tamil"
                value=""
                data-e-value="ta"
                disabled
            >
                தமிழ் (ta)
            </option>
            <option
                title="Thai"
                value=""
                data-e-value="th"
                disabled
            >
                ไทย (th)
            </option>
            <option
                title="Tagalog"
                value=""
                data-e-value="tl"
                disabled
            >
                Tagalog (tl)
            </option>
            <option
                title="Tswana"
                value=""
                data-e-value="tn"
                disabled
            >
                Setswana (tn)
            </option>
            <option
                title="Turkish"
                value="17"
                data-e-value="tr"
            >
                Türkçe (tr)
            </option>
            <option
                title="Ukrainian"
                value=""
                data-e-value="uk"
                disabled
            >
                Українська (uk)
            </option>
            <option
                title="Vietnamese"
                value=""
                data-e-value="vi"
                disabled
            >
                Tiếng Việt (vi)
            </option>
            <option
                title="Wolof"
                value=""
                data-e-value="wo"
                disabled
            >
                Wolof (wo)
            </option>
            <option
                title="Welsh"
                value="2"
                data-e-value="cy"
                disabled
            >
                Cymraeg (cy)
            </option>
            <option
                title="Xhosa"
                value=""
                data-e-value="xh"
                disabled
            >
                isiXhosa (xh)
            </option>
            <option
                title="Yoruba"
                value=""
                data-e-value="yo"
                disabled
            >
                Yorùbá (yo)
            </option>
            <option
                title="Zulu"
                value=""
                data-e-value="zu"
                disabled
            >
                isiZulu (zu)
            </option>
        </select>
    )
}

export default LanguageSelect;