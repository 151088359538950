import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { store } from './store/';
import { Provider } from 'react-redux';
import axios from 'axios';
import * as serviceWorker from './serviceWorker';
import 'nprogress/nprogress.css';
import { saveState } from './store/persistState';
import throttle from 'lodash/throttle';
import globalConfig from './global.config';

axios.defaults.baseURL = globalConfig.baseUrl;

/* 
Persisting State
 */

store.subscribe(throttle(() => {
    saveState(store.getState());
}, 1000));


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
