import React, {ReactElement } from 'react';
import {useNavigate} from 'react-router-dom';

const CookiesPolicy = (): ReactElement => {
    const navigate = useNavigate();

    return <>
        <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
            <div className="col-12 col-md-6">
                <div className="page-content text-center">
                    <h1 className="h2 text-900 mb-5 text-uppercase">We use Cookies</h1>
                    <div className="text-widget">
                        <p>We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and to understand where our visitors are coming from. By browsing our website, you consent to our use of cookies and other tracking technologies.</p>
                    </div>
                    <button onClick={event => navigate(-1)} className="color-danger btn btn-link text-underline">Go Back</button>
                </div>
            </div>
        </div>
    </>
}

export default CookiesPolicy;