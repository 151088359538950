import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { passwordStrengthValidator } from '../helpers/validation';
import { loadUser } from '../store/reducers/auth/authSlice';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import NProgress from 'nprogress';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const Register = (): ReactElement => {
    const navigate = useNavigate();
    const userSelector = useAppSelector((state) => state.auth.user);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(userSelector !== null && userSelector?.Email === formik.values.Email) {
            navigate('/profile?onboarding=true');
        }
    }, [userSelector]);

    const formik = useFormik({
        initialValues: {
            Name: '',
            Email: '',
            Password: '',
            ConfirmPassword: '',
        },
        validationSchema: Yup.object({
            Name: Yup.string().required('Required'),
            Email: Yup.string().email().required('Required'),
            Password: Yup.string().required('Required').test('password-strength', function (value) {
                const passwordField = document.getElementById('Password') as HTMLInputElement;
                return passwordStrengthValidator(passwordField);
            }),
            ConfirmPassword: Yup.string().test('passwords-match', function (value) {
                return this.parent.Password === value
            })
        }),
        onSubmit: async (values) => {
            setLoading(true);
            NProgress.start()
            try {
                const response = await axios.post('/users/register', values);
                if (response && response.data) {
                    Cookies.set('access_token', response.data.access_token, { expires: 7 });
                    dispatch(loadUser('Welcome'));
                }
            } catch (e: any) {
                toast.error(e.response.message);
                if (e.response && e.response.data && e.response.data.message && e.response.data.message === 'duplicate data') {
                    /* todo: magic string */
                    toast.error("This email is already registered with us.");
                } else {
                    toast.error(e.response.message);
                }
            } finally {
                NProgress.done();
                setLoading(false);
            }
        },
        validateOnBlur: true
    });

    return <>
        <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
            <div className="page-form-wrap col">
                <div className="page-form">
                    <h1 className="text-uppercase text-900 text-center mt-0 mb-4">Register</h1>

                    <form className={loading ? 'loading' : ''} data-success-message="#post-submit-note" noValidate onSubmit={formik.handleSubmit}>
                        <div id="general-error" />
                        <div>
                            <div className="form-group with-icon floating-label-group">
                                <input className={formik.touched.Name && formik.errors.Name ? "error form-control" : "form-control"}
                                    type="text"
                                    id="Name"
                                    placeholder="Name"
                                    data-placement="top"
                                    data-trigger="hover"
                                    autoComplete="Name"
                                    {...formik.getFieldProps('Name')}
                                />
                                <div className="after-control">
                                    <label className="floating-label" htmlFor="Name">Name</label>
                                    <span className="focus-effect" />
                                    <div className="popover bs-popover-top">
                                        <div className="arrow" />
                                        <div className="popover-body">
                                            <span className="no-wrap">Type your full name</span>
                                        </div>
                                    </div>
                                    <span className="form-control-icon fal fa-user-alt" />
                                </div>
                            </div>

                            <div className="form-group with-icon floating-label-group">
                                <input className={formik.touched.Email && formik.errors.Email ? "error form-control" : "form-control"}
                                    type="text"
                                    id="Email"
                                    placeholder="Email"
                                    autoComplete="Email"
                                    {...formik.getFieldProps('Email')}
                                />
                                <div className="after-control">
                                    <label className="floating-label" htmlFor="email">Email</label>
                                    <span className="focus-effect" />
                                    <div className="popover bs-popover-top">
                                        <div className="arrow" />
                                        <div className="popover-body">
                                            <span className="no-wrap">Enter a valid email</span>
                                        </div>
                                    </div>
                                    <span className="form-control-icon fal fa-envelope" />
                                </div>
                            </div>
                            <div className="form-group with-icon floating-label-group">
                                <input className={formik.touched.Password && formik.errors.Password ? "error form-control" : "form-control"}
                                    id="Password"
                                    type="password"
                                    autoComplete="Password"
                                    onFocus={event => passwordStrengthValidator(event.currentTarget)}
                                    data-progressbar="#password-strength-progress"
                                    placeholder="Password"
                                    {...formik.getFieldProps('Password')}

                                />
                                <div className="after-control">
                                    <label className="floating-label" htmlFor="Password">Password</label>
                                    <span className="focus-effect" />
                                    <div className="popover bs-popover-top">
                                        <div className="arrow" />
                                        <div className="popover-header">Password Requirements</div>
                                        <div className="popover-body">
                                            <ul className="error-dependant-list">
                                                <li className="no-wrap error-length">Between 7-16 Characters</li>
                                                <li className="no-wrap error-uppercase">An Upper Case Letter</li>
                                                <li className="no-wrap error-number">A Number</li>
                                                <li className="no-wrap error-special-characters">At Least 1 of the Following (_,#,%,*,@)</li>
                                                <li className="no-wrap error-prohibited-characters">None of the Following ($,&,=,!)</li>
                                            </ul>
                                            <div className="progress">
                                                <div id="password-strength-progress" className="progress-bar" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={0}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="form-control-icon fal fa-lock" />
                                </div>
                            </div>
                            <div className="form-group with-icon floating-label-group">
                                <input className={formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? "error form-control popover-only-on-error" : "form-control popover-only-on-error"}
                                    type="password"
                                    id="ConfirmPassword"
                                    placeholder="Confirm Password"
                                    {...formik.getFieldProps('ConfirmPassword')}
                                    required />
                                <div className="after-control">
                                    <label className="floating-label" htmlFor="ConfirmPassword">Confirm Password</label>
                                    <span className="focus-effect" />
                                    <div className="popover bs-popover-top"><div className="arrow" />
                                        <div className="popover-body">
                                            <span className="no-wrap error-label">Passwords do not match!</span>
                                        </div>
                                    </div>
                                    <span className="form-control-icon fal fa-lock" />
                                </div>
                            </div>
                            <div className="form-group btn-wrap">
                                <button type="submit"
                                    className="btn btn-block btn-md btn-primary"
                                    title="Register">
                                    <span className="btn-text">Register</span>
                                    <i className="btn-icon far fa-spinner-third fa-spin" />
                                </button>
                            </div>

                        </div>
                        <div className="text-center fonts-secondary color-light">
                            Already Registered? <Link className="text-borderline color-light" to="/">Login</Link>
                        </div>
                    </form>



                    <div id="post-submit-note" className="text-center post-submit-message color-light">
                        <p className="mb-0 ">Please Check your email for a verification link</p>
                        After verification, come back here and <Link className="text-borderline color-light" to="/">Login</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Register;