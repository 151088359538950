import React, {ReactElement, useEffect, useState} from 'react';
import { useAppSelector } from '../../../store/hooks';
import MapBox from '../MapBox';
import NextButton from '../PanelNavigation/NextButton';
import {useNavigate, useParams} from "react-router-dom";
import AssetText from "../AssetText";
import {toast} from "react-toastify";

interface props{

}

const SeriesSummary = (props: props): ReactElement => {

    const { seriesId } = useParams();

    return <div>
        <header>
            <NextButton label="Next" />
            <h3 className="h4">Summary</h3>
        </header>
        {
            seriesId && seriesId.length > 0 ? <AssetText modelType={'series'} modelId={seriesId} attribute={'Summary'} /> : ''
        }
    </div>
};

export default SeriesSummary;