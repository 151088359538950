import React, {ReactElement} from 'react';
import { useAppSelector } from '../../../store/hooks';
import MapBox from '../MapBox';
import NextButton from '../PanelNavigation/NextButton';

interface props{

}

const SeriesCenterPoint = (props: props): ReactElement => {

    const centerPointMapDrawOptions = {
        polyline: false,
        polygon: false,
        circlemarker: false ,
        circle: false, // Turns off this drawing tool
        rectangle: false ,
        marker: true
    }
   

    const series = useAppSelector(state => state.entities.series.selectedSeriesData);

    return <div>
        <header>
            <NextButton label="Next" />
            <h3 className="h4">Center Point</h3>
        </header>
        <MapBox attribute={'Initial_geo'} modelType={'series'} mapBoxDraw={centerPointMapDrawOptions} />
    </div>
};

export default SeriesCenterPoint;