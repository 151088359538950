import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { iTabPanel } from "..";
import globalConfig from "../../../../global.config";
import { apiInit } from "../../../middlewares/api.middleware";



export interface iFeature {
    _id: string;
    Name: string;
    JSON: string;
    Era: string;
    Tag: string;
    Lng: number;
    MapZoom: number;
    Lat: number;
    Elv: number;
    Mph: number;
    Deg: number;
    Geo: String;
    Exp: Date;
    WikiID: number;
    GoogID: String;
    Sequence: number;
    HasMap: boolean;
    Active: boolean;
    CompletionPercentage: number;
    Summary: Record<string, any>
    Img: Record<string, any>;
    BoxImg: Record<string, any>;
    BoxImgCap: Record<string, any>;
    DefaultAsset: Record<string, any>;
    Series: Record<string, any>;
    User: Record<string, any>
}

interface iSeriesInitiaState {
    loading: boolean;
    selectedFeature: null | iFeature;
    panelsList: Array<iTabPanel>;
}

const getDefaultPanels = (): iTabPanel[] => [
    { isComplete: true, url: 'draw-the-path' },
    { isComplete: false, url: 'image' },
    { isComplete: false, url: 'tags' },
    { isComplete: false, url: 'script' },
]


const initialState: iSeriesInitiaState = {
    loading: false,
    selectedFeature: null,
    panelsList: [
        { isComplete: true, url: 'draw-the-path' },
        { isComplete: false, url: 'image' },
        { isComplete: false, url: 'tags' },
        { isComplete: false, url: 'script' },
    ]
}


const featureSlice = createSlice({
    name: 'feature',
    initialState,
    reducers: {
        featureReceived: (state, action: PayloadAction<any>) => {
            const payload = action.payload;
            if (payload._id) {
                state.selectedFeature = action.payload;
            }
            state.loading = false;

            if (!state.panelsList) state.panelsList = getDefaultPanels();

            state.panelsList[0].isComplete = payload.Geo && payload.Geo.length > 50;
            state.panelsList[1].isComplete = !!(payload.BoxImg && payload.BoxImg._id && payload.BoxImg.AltText && payload.BoxImg.AltText.length > 0);
            state.panelsList[2].isComplete = payload.Tag && payload.Tag.length > 0;
            state.panelsList[3].isComplete = (payload.DefaultAsset && payload.DefaultAsset.Text && payload.DefaultAsset.Text.length > 0)

        },
        requestInitiated: (state, action: PayloadAction<any>) => {
            state.loading = true;       
        },
        requestCompleted: (state, action: PayloadAction<any>) => {
            state.loading = false;
        },
        captionUpdated: (state, action: PayloadAction<any>) => {
            state.loading = false;
            const payload = action.payload;
            state.panelsList[1].isComplete = !!(payload && payload._id && payload.AltText && payload.AltText.length > 0);
        },
        requestFailed: (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (action.payload && action.payload.messsage) {
                toast.error(action.payload.message, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }
});


const { featureReceived, requestFailed, requestInitiated, requestCompleted, captionUpdated } = featureSlice.actions;

type newFeature = {
    Name: string,
    HasMap: boolean,
    Series: string
}
export const createNewFeature = (data: newFeature, messageOnError = '') => {
    return apiInit({
        ...globalConfig.endpoints.feature.createNewFeature,
        data,
        onStart: requestInitiated.type,
        onSuccess: featureReceived.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        hideErrorNotification: true
    });
}

export const getFeatureById = (featureId: string, messageOnError = '') => {
    return apiInit({
        url: globalConfig.endpoints.feature.getFeatureById.url + '/' + featureId,
        method: globalConfig.endpoints.series.getSeriesById.method,
        onStart: requestInitiated.type,
        onSuccess: featureReceived.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        hideErrorNotification: true
    });
}

export const updateFeature = (featureId: string, data: Record<string, unknown>, successMessage: string, messageOnError = '') => {
    return apiInit({
        method: globalConfig.endpoints.feature.updateFeature.method,
        url: globalConfig.endpoints.feature.updateFeature.url + '/' + featureId,
        data,
        onStart: requestInitiated.type,
        onSuccess: featureReceived.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        onDone: { type: 'notification', payload: { message: successMessage } }
    });
};

export const updateCaption = (assetId: string, newCaption: string, successMessage: string, messageOnError = '') => {
    return apiInit({
        method: globalConfig.endpoints.asset.updateAssetById.method,
        url: globalConfig.endpoints.asset.updateAssetById.url + '/' + assetId,
        data: {AltText: newCaption},
        onStart: requestInitiated.type,
        onSuccess: captionUpdated.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        onDone: { type: 'notification', payload: { message: successMessage } }
    });
};

export const updateDefaultAsset = (featureId: string, newScript: string, successMessage: string, messageOnError = '') => {
    return apiInit({
        method: globalConfig.endpoints.feature.updateScript.method,
        url: globalConfig.endpoints.feature.updateScript.url + '/' + featureId,
        data: {script: newScript},
        onStart: requestInitiated.type,
        onSuccess: featureReceived.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        onDone: { type: 'notification', payload: { message: successMessage } }
    });
};


export default featureSlice.reducer;