import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { toast } from 'react-toastify';
import globalConfig from '../../../global.config';
import { useAppSelector } from '../../../store/hooks';
import { iTabPanel } from '../../../store/reducers/entities';
type Props = {
    modelType: 'feature' | 'series';
    panelNames: Array<string>;
    panelsAttribute?: 'panelsList' | 'panelsListFinal'
}


const PanelsNavigation = ({ panelNames, modelType, panelsAttribute='panelsList' }: Props): ReactElement => {

    const location = useLocation();
    const navigate = useNavigate();
    const { featureId } = useParams();
    const [currentActive, setCurrentActive] = useState(-1);
    const seriesPanels = useAppSelector(state => state.entities.series[panelsAttribute]);
    const featurePanels = useAppSelector(state => state.entities.feature.panelsList);
    const [panels, setPanels] = useState<iTabPanel[]>([]);
    const timer = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (panels && panels.length) {
            window.addEventListener(globalConfig.events.stepsNavigation.next, (e) => {
                if (timer) clearTimeout(timer.current!);
                timer.current = setTimeout(() => {
                    if (panels && panels.length && currentActive < panels.length - 1) {
                        let next = currentActive;
                        document.getElementById('btn-progress-' + (next + 1))?.click();
                    }
                }, 100);
            })
            window.addEventListener(globalConfig.events.stepsNavigation.prev, (e) => {
                if (timer) clearTimeout(timer.current!);
                timer.current = setTimeout(() => {
                    if (panels && panels.length && currentActive >= 1) {
                        document.getElementById('btn-progress-' + (currentActive - 1))?.click();
                    }
                }, 100);
            })
        }

        return () => {
            window.removeEventListener(globalConfig.events.stepsNavigation.next, () => { });
            window.removeEventListener(globalConfig.events.stepsNavigation.prev, () => { });
        }

    }, [panels, currentActive]);

    useEffect(() => {
        if (modelType === 'series') {
            setPanels(seriesPanels);
        } else {
            setPanels(featurePanels);
        }
    }, [featurePanels, seriesPanels]);

    useEffect(() => {
        const currentPatch = location.pathname;
        let foundPage = false;
        for (let i = 0; i < panels.length; i++) {
            if (currentPatch.endsWith(panels[i].url)) {
                document.getElementById('btn-progress-' + i)?.click();
                foundPage = true;
                break;
            }
        }
        if (!foundPage) {
            if (currentPatch.endsWith('/setting') || currentPatch.endsWith('/setting/')) document.getElementById('btn-progress-0')?.click();
        }
        if (!foundPage && featureId) {
            if (currentPatch.endsWith('/' + featureId) || currentPatch.endsWith('/'+ featureId +'/')) document.getElementById('btn-progress-0')?.click();
        }
        if (!foundPage) {
            if (currentPatch.endsWith('/finalize') || currentPatch.endsWith('/finalize/')) document.getElementById('btn-progress-0')?.click();
        }
    }, [panels])

    const handleProgressBarClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const target = e.target as HTMLButtonElement;
        const index = parseInt(target.getAttribute('data-index') as string);
        if (currentActive === -1 && index === 0) {
            setCurrentActive(index);
            navigate(panels[index].url);
            return;
        }
        if (index === currentActive) return;
        let newTarget = currentActive;
        if (index === 0) newTarget = index;
        let inCompleteFound = false;
        if (index > 0 && index > currentActive) {
            for (let i = index; i > 0; i--) {
                if (!panels[i].isComplete) {
                    newTarget = i;
                    inCompleteFound = true;
                }
            }
            if (!inCompleteFound) {
                newTarget = index;
            }
        } else if (index < currentActive) {
            newTarget = index;
        }
        setCurrentActive(newTarget);
        if (newTarget !== index) {
            toast.error(`You need to complete ${panelNames[newTarget]} before you can go to ${panelNames[index]}`);
        }
        navigate(panels[newTarget].url);
    }


    return <div className="multisteps-component__progress">
        {
            [...panels].map((panel, index) => {
                return <button id={"btn-progress-" + index} key={'series-setting-' + panel.url} onClick={handleProgressBarClick} data-index={index} className={index <= currentActive ? "multisteps-component__progress-btn active" : "multisteps-component__progress-btn"} type="button" title={panelNames[index]}>{panelNames[index]}</button>
            })
        }
    </div>
}

export default PanelsNavigation