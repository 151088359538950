import { ThunkMiddleware } from "redux-thunk";
import { createAction } from "@reduxjs/toolkit";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notificationAction = createAction<iNotification>("notification")

export type iNotificationTypes = 'error' | 'success' | 'info' | 'warn';

export interface iNotification {
    type?: iNotificationTypes;
    message: string;
}

const notificationMiddleware: ThunkMiddleware = (store) => (next) => (action) => {
    if(action.type === notificationAction.type){
        const notificationData: iNotification = action.payload;
        
        if(notificationData.message && notificationData.message.length){
            if(notificationData.type === 'success') { 
                toast.success(notificationData.message, {position: toast.POSITION.TOP_RIGHT});
            } else if(notificationData.type === 'error') {
                toast.error(notificationData.message, {position: toast.POSITION.TOP_RIGHT});
            } else if(notificationData.type === 'info') {
                toast.info(notificationData.message, {position: toast.POSITION.TOP_RIGHT});
            } else if(notificationData.type === 'warn') {
                toast.warn(notificationData.message, {position: toast.POSITION.TOP_RIGHT});
            } else {
                toast(notificationData.message);
            }
        }
        
    } else {
        next(action);
    }
}
export default notificationMiddleware;