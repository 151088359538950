import nProgress from 'nprogress';
import React, { ReactElement, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getMySeriesList } from '../store/reducers/entities/series/seriesSlice';

const SeriesList = (): ReactElement => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const stateLoading = useAppSelector(state => state.entities.series.loading);
    const seriesList = useAppSelector(state => state.entities.series.list);

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        if (stateLoading) {
            nProgress.start();
            setLoading(true);
        }
        else {
            setLoading(false);
            nProgress.done();
        }
    }, [stateLoading]);


    return <>

        <h1 className="h2 text-900 text-uppercase">My Tours</h1>
        {
            seriesList && seriesList.length ?
                <div className="previous-tours">
                    {seriesList.map((tour, i) => {
                        return (
                            <Link
                                to={'/tours/' + tour._id}
                                className="btn btn-shadow with-icon btn-block text-left"
                                key={"tour-" + tour._id}
                            >
                                {tour.Name}{" "}
                                {tour.Status === 'draft' && <span className="badge badge-warning text-uppercase ml-4">{tour.Status}</span>}
                                {tour.Status === 'review' && <span className="badge badge-info text-uppercase ml-4">Under Review</span>}
                                {tour.Status === 'publish' && <span className="badge badge-success text-uppercase ml-4">{tour.Status}</span>}

                                <i className="fal fa-angle-right" />
                            </Link>
                        );
                    })}
                </div>
                : <></>
        }
        {
            !loading && !(seriesList && seriesList.length) ?
                <>
                    <p>You have not added any tours yet!</p>
                    <button onClick={e => navigate('new')} className="btn btn-primary">Add new tour</button>
                </>
                : <></>
        }
    </>
}

export default SeriesList;