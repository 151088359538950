import axios, { Method } from "axios"
import Cookies from "js-cookie";

interface iApi{
    url: string,
    data: Record<string, unknown> | FormData,
    method: Method
}

const api = ({url, data, method }: iApi) => {
    const headers = { Authorization: `Bearer ${Cookies.get('access_token')}` };
    return axios({ url, method, data, headers });
}

export default api;