import React, {ReactElement, useEffect, useState} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { loadUser } from '../../store/reducers/auth/authSlice';
import NProgress from 'nprogress';



const ProtectedRoute =  (): ReactElement => {
    const navigate = useNavigate();
    const userSelector = useAppSelector((state) => state.auth.user);
    const userLoading = useAppSelector((state) => state.auth.loading);
    const token = Cookies.get('access_token');
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!token) {
            navigate('./');
        }
    }, [])

    useEffect(() => {
        if(userLoading){
            NProgress.start();
        } else  {
            NProgress.done();
        }
    }, [userLoading]);
    
    if(token && !(userSelector && userSelector._id && userSelector._id.length === 24)) {
        dispatch(loadUser(''));
        return <></>
    }
    return <Outlet />;
}

export default ProtectedRoute