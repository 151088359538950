import React, {ReactElement } from 'react';

const NotFound = (): ReactElement => {

    return <>
        <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
            <div className="col-12 col-md-6">
                <div className="page-content text-center">
                    <h1 className="h1 text-900 mb-5 text-uppercase">Page not found!!!</h1>
                </div>
            </div>
        </div>
    </>
}

export default NotFound;