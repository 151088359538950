import { Method } from "axios";
const envBasedSetting = {
    development: {
        baseUrl: 'https://0tu02bxsd2.execute-api.us-east-1.amazonaws.com/dev',
        s3MediaBucket: 'https://dev-media-ayt.s3.amazonaws.com/',
    },
    production: {
        baseUrl: 'https://0tu02bxsd2.execute-api.us-east-1.amazonaws.com/dev',
        s3MediaBucket: 'https://dev-media-ayt.s3.amazonaws.com/',
    }
}
const commonSetting = {
    socialAuth: {
        fb: {
            appId: "180031860012956"
        },
        apple: {
            serviceId: 'com.maverickmaven.cognito2'
        }
    },
    endpoints: {
        users: {
            getCurrentUser: {
                url: '/users/me',
                method: 'GET' as Method
            },
            updateCurrentUser: {
                url: '/users/me',
                method: 'PATCH' as Method
            },
            updateAvatar: {
                url: '/users/update-avatar',
                method: 'PUT' as Method
            }
        },
        series: {
            getMySeries: {
                url: '/series/mine',
                method: 'GET' as Method
            },
            getSeriesById: {
                url: '/series',
                method: 'GET' as Method
            },
            createNewSeries: {
                url: '/series/create',
                method: 'POST' as Method
            },
            updateSeries: {
                url: '/series/update',
                method: 'PATCH' as Method
            },
            updateBoxImg: {
                url: '/series/update-image',
                method: 'PUT' as Method
            },
            updateScript: {
                url: '/series/script',
                method: 'POST' as Method
            },
        },
        feature: {
            createNewFeature: {
                url: '/features/create',
                method: 'POST' as Method
            },
            updateFeature: {
                url: '/features/update',
                method: 'PATCH' as Method
            },
            getFeatureById: {
                url: '/features',
                method: 'GET' as Method
            },
            updateBoxImg: {
                url: '/features/update-image',
                method: 'PUT' as Method
            },
            updateScript: {
                url: '/features/script',
                method: 'POST' as Method
            },
        },
        asset: {
            updateAssetById: {
                url: '/assets/update',
                method: 'PATCH' as Method
            }
        }
    },
    externalEndpoints: {
        wikipedia: {
            getPages: 'https://en.wikipedia.org/w/api.php?format=json&origin=*&action=query&list=geosearch&gsprimary=all&gsradius=2000&gscoord=',
            getPageImages: 'https://en.wikipedia.org//w/api.php?action=query&origin=*&format=json&prop=images&pageids=',
            getImageInfo: 'https://en.wikipedia.org/w/api.php?action=query&origin=*&format=json&prop=imageinfo&iiprop=url&titles=',
        }
    },
    variables: {
        loadingIndicatorTimeoutSeconds: 10000,
        wikipediaArticlesLimit: 15
    },
    events: {
        stepsNavigation: {
            next: 'goToNextPanel',
            prev: 'goToPrevPanel'
        }
    }
}
type appEnv = 'development' | 'production';
const mode = process.env.NODE_ENV as appEnv;
const globalConfig = {
    ...commonSetting,
    ...envBasedSetting[mode]
}

export default globalConfig;
