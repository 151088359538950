
import React, { FormEvent, FormEventHandler, ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateFeature } from '../../store/reducers/entities/feature/featureSlice';
import { getMySeriesById, updateSeries } from '../../store/reducers/entities/series/seriesSlice';

type propsType = {
    modelType: 'feature' | 'series';
    modelId: string;
    currentName? : string
}

const UpdateName = (props: propsType): ReactElement => {
    const dispatch = useAppDispatch();

    const [updatingName, setUpdatingName] = useState(false);
    const [newName, setNewName] = useState('');
    const [loading, setLoading] = useState(false);
    const series = useAppSelector(state => state.entities.series.selectedSeriesData);
    const seriesStateLoading = useAppSelector(state => state.entities.series.loading);
    const featureStateLoading = useAppSelector(state => state.entities.feature.loading);


    const handleUpdateName = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        if(props.modelType === 'series') {
            dispatch(updateSeries(props.modelId, {Name: newName}, 'Saved!', 'Failed to update name!'));
        } else {
            dispatch(updateFeature(props.modelId, {Name: newName}, 'Saved!', 'Failed to update name!'));
        }
    }

    useEffect(() => {
        if(props.modelType === 'series' && loading) {
            setLoading(seriesStateLoading);
            if(!seriesStateLoading) {
                setNewName('');
                setUpdatingName(false);
            }
        } 
    }, [seriesStateLoading])
    
    useEffect(() => {
        if(props.modelType === 'feature' && loading) {
            setLoading(featureStateLoading);
            if(!featureStateLoading) {
                setNewName('');
                setUpdatingName(false);
                if(series) dispatch(getMySeriesById(series._id))
            }
        }
    }, [featureStateLoading])
    

    return <>
        {
            updatingName && <form className={loading ? "loading update-name" : "update-name"} onSubmit={handleUpdateName}>
                <div className="custom-form-group">
                    <input type="text" placeholder="Write new name" defaultValue={props.currentName ? props.currentName : ''} onChange={e => setNewName(e.currentTarget.value)} />
                    <button type="submit"><i className="fa fa-check" /></button>
                </div>
            </form>
        }

        {/* todo: magic string */}
        <button className={updatingName ? 'btn-edit-name btn-cancel-action' : 'btn-edit-name'} title="Edit series name" onClick={e => {
            if (updatingName) {
                setNewName('');
            }
            setUpdatingName(!updatingName)
        }}>
            {updatingName ? <i className="fa fa-times" /> : <i className="fa fa-pencil" />}
        </button>
    </>
}

export default UpdateName