import React, { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import LanguageSelect from './partials/LanguageSelect';
import IntlTelInput from "react-intl-tel-input";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "react-intl-tel-input/dist/main.css";
import generateUsername from '../helpers/username';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadUser, updateUser } from '../store/reducers/auth/authSlice';

import AvatarIcon from '../images/profile_icon.jpg';
import { toast } from 'react-toastify';
import ImageUploader from './partials/ImageUploader';
import globalConfig from '../global.config';


const Profile = (): ReactElement => {
    const dispatch = useAppDispatch();
    const triggerCropOnEvent = 'cancel_avatar_update';
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [newProfileImage, setNewProfileImage] = useState<File | null>(null);
    const userSelector = useAppSelector((state) => state.auth.user);
    const userLoadingState = useAppSelector((state) => state.auth.loading);
    const [imageManagerResponse, setImageManagerResponse] = useState<Record<string, unknown>>();

    useEffect(() => {
        if (loading && !userLoadingState) {
            setLoading(false);
            setNewProfileImage(null);
        }
    }, [userLoadingState])

    useEffect(() => {
        if (imageManagerResponse && !imageManagerResponse.loading) {
            setLoading(false);
            setNewProfileImage(null);
            /* todo: magic string */
            dispatch(loadUser('Your avatar has been updated'));
        }
    }, [imageManagerResponse])


    
    const defaultValues = {
        Email: '',
        LanguageID: 1,
        Mobile: '',
        Username: '',
        Bio: '',
        NickName: '',
        Location: '',
        EthereumWallet: '',
        AcceptDisclosures: false,
        AcceptTerms: false,
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...defaultValues,
            ...userSelector
        },
        validationSchema: Yup.object({
            Username: Yup.string().required('Required'),
            Location: Yup.string().required('Required'),
            Bio: Yup.string().min(0).max(500),
            AcceptDisclosures: Yup.boolean().oneOf([true]),
            AcceptTerms: Yup.boolean().oneOf([true]),
        }),
        onSubmit: values => {
            setLoading(true);
            /* todo: magic string */
            dispatch(updateUser('Your profile has been updated.', {
                ...values
            }));
        },
    });

    if (!userSelector) {
        return <p />
    }


    const onFileBrowse = (e: any) => {
        const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg']
        const file = e.currentTarget.files[0];
        if (allowedTypes.includes(file.type)) {
            setNewProfileImage(file);
        } else {
            /* todo: magic string */
            toast.error('Please select png or jpg file', { position: toast.POSITION.TOP_CENTER });
        }
    }

    const imageManagerCallback = (data: any) => {
        setImageManagerResponse(data)
    }

    return (
        <>

            <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
                <div className="col-12 col-xl-8">
                    <div className="page-form">
                        {
                            searchParams.get('onboarding') && searchParams.get('onboarding') === 'true' ? '' : <Link
                                to="/dashboard"
                                title="Go to Dashboard"
                                className="closeAndGoBack"
                            >
                                <i className="fal fa-times" />
                            </Link>
                        }

                        <form className={loading || userLoadingState ? 'loading' : ''} onSubmit={formik.handleSubmit} noValidate>
                            <input type="hidden" {...formik.getFieldProps('_id')} />
                            <div className="row flex-row-reverse">
                                <div className="col-12 col-md-6">
                                    <div className="upload-picture">
                                        <input type="file" className="upload-action" accept=".jpg,.jpeg,.png" onChange={onFileBrowse} />
                                        {userSelector.Photo && userSelector.Photo.MimeType ? <>
                                            <div className="current-image">
                                                <img id="profile_picture" alt="profile" src={globalConfig.s3MediaBucket + userSelector.Photo._id + '.' + userSelector.Photo.MimeType.split('/')[1] + '?v=' + +new Date(userSelector.Photo.updatedAt)} />
                                            </div>
                                            <div className="picture-caption">
                                                {/* todo: magic string */}
                                                Change profile picture
                                            </div>
                                        </> : <>
                                            <div className="current-image">
                                                <img id="profile_picture" alt="profile" src={AvatarIcon} />
                                            </div>
                                            <div className="picture-caption">
                                                {/* magic string */}
                                                Upload profile picture
                                            </div>
                                        </>}
                                    </div>
                                    <div className="input-group floating-label-group">
                                        <input
                                            type="text"
                                            id="Username"
                                            onFocus={e => {
                                                formik.values.Username = e.currentTarget.value;
                                            }}
                                            className={formik.touched.Username && formik.errors.Username ? "error form-control" : "form-control"}
                                            placeholder="Username"
                                            {...formik.getFieldProps('Username')}
                                        />
                                        <div className="after-control">
                                            <label
                                                className="floating-label"
                                                htmlFor="Username"
                                            >
                                                Username
                                            </label>
                                            <span className="focus-effect" />
                                            <div className="popover bs-popover-top">
                                                <div className="arrow" />
                                                <div className="popover-body">
                                                    <span className="no-wrap">
                                                        Username must be unique
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            !userSelector.Username ? <div className="input-group-append">
                                                <button
                                                    id="username-generator"
                                                    data-target="Username"
                                                    onClick={e => {
                                                        const targetInput = document.getElementById(e.currentTarget.getAttribute('data-target') as string);
                                                        if (targetInput) {
                                                            generateUsername(targetInput as HTMLInputElement);
                                                        }
                                                    }}
                                                    className="btn btn-dark less-dark"
                                                    type="button"
                                                >
                                                    Regenerate
                                                </button>
                                            </div> : ''
                                        }
                                    </div>
                                    <div className="form-group floating-label-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="NickName"
                                            placeholder="Nickname"
                                            {...formik.getFieldProps('NickName')}
                                        />
                                        <div className="after-control">
                                            <label
                                                className="floating-label"
                                                htmlFor="NickName"
                                            >
                                                Nickname
                                            </label>
                                            <span className="focus-effect" />
                                            <div className="popover bs-popover-top">
                                                <div className="arrow" />
                                                <div className="popover-body">
                                                    <span className="no-wrap">
                                                        Enter your Nickname
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group floating-label-group">

                                        <LanguageSelect id="LanguageID" className="custom-select form-control" placeholder="Writing Language" {...formik.getFieldProps('LanguageID')} />

                                        <div className="after-control">
                                            <span className="focus-effect" />
                                            <div className="popover bs-popover-top">
                                                <div className="arrow" />
                                                <div className="popover-body">
                                                    <span className="no-wrap">
                                                        Select your preferred language
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group high-z">
                                        <IntlTelInput
                                            defaultValue={userSelector.Mobile}
                                            fieldName="MobileInput"
                                            containerClassName="intl-tel-input"
                                            inputClassName="form-control"
                                            onPhoneNumberChange={(
                                                isValid,
                                                value,
                                                selectedCountryData,
                                                fullNumber
                                            ) => {
                                                formik.handleChange("Mobile")(fullNumber);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group floating-label-group">
                                        <input
                                            type="text"
                                            id="Location"
                                            className={formik.touched.Location && formik.errors.Location ? "error form-control" : "form-control"}
                                            placeholder="Hometown (City, Country)"
                                            {...formik.getFieldProps('Location')}
                                        />
                                        <div className="after-control">
                                            <label
                                                className="floating-label"
                                                htmlFor="Location"
                                            >
                                                Hometown (City, Country)
                                            </label>
                                            <span className="focus-effect" />
                                            <div className="popover bs-popover-top">
                                                <div className="arrow" />
                                                <div className="popover-body">
                                                    <span className="no-wrap">
                                                        Please enter your city followed by your
                                                        country.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group floating-label-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="EthereumWallet"
                                            placeholder="Ethereum Wallet"
                                            {...formik.getFieldProps('EthereumWallet')}
                                        />
                                        <div className="after-control">
                                            <label
                                                className="floating-label"
                                                htmlFor="EthereumWallet"
                                            >
                                                Your Ethereum Wallet address
                                            </label>
                                            <span className="focus-effect" />
                                            <div className="popover bs-popover-top">
                                                <div className="arrow" />
                                                <div className="popover-body">
                                                    <span className="no-wrap">
                                                        Enter your link to Venmo
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group mb-0 floating-label-group">
                                        <textarea
                                            className={formik.touched.Bio && formik.errors.Bio ? "error form-control" : "form-control"}
                                            id="Bio"
                                            maxLength={500}
                                            placeholder="Start typing a short bio..."
                                            {...formik.getFieldProps('Bio')}
                                        />
                                        <div className="after-control">
                                            <label className="floating-label" htmlFor="Bio">
                                                Start typing a short bio...
                                            </label>
                                            <span className="focus-effect" />
                                            <div className="popover bs-popover-top">
                                                <div className="arrow" />
                                                <div className="popover-body">
                                                    <span className="no-wrap">Enter your bio</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix text-right fonts-primary color-light mb-3">
                                        <span id="bio-current-length">{formik.values.Bio.length}</span>/
                                        <span id="bio-max-length">500</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-7">
                                    <div className="custom-checkbox-control small">
                                        <label htmlFor="agree-cookies" className={formik.touched.AcceptDisclosures && formik.errors.AcceptDisclosures ? "color-danger" : ""}>
                                            <input id="agree-cookies" type="checkbox" {...formik.getFieldProps('AcceptDisclosures')} />
                                            <span>I accept the GDPR, CASIL & All <Link to="/cookies" className="color-inherit text-underline">Cookie Disclosures</Link></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5">
                                    <div className="custom-checkbox-control small">
                                        <label htmlFor="agreeToTerms" className={formik.touched.AcceptTerms && formik.errors.AcceptTerms ? "color-danger" : ""}>
                                            <input id="agreeToTerms" type="checkbox" {...formik.getFieldProps('AcceptTerms')} />
                                            <span>I agree to the terms.</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 mb-3">
                                <div className="col-12 col-sm-6">
                                    <Link to="/dashboard" className="btn btn-md btn-dark btn-block text-uppercase">
                                        {
                                            searchParams.get('onboarding') && searchParams.get('onboarding') === 'true' ? 'Do it later!' : 'Cancel'
                                        }
                                    </Link>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <button
                                        type="submit"
                                        className="btn btn-md btn-primary btn-block text-uppercase mt-sm-0 mt-2"
                                    >
                                        {
                                            searchParams.get('onboarding') && searchParams.get('onboarding') === 'true' ? 'save' : 'update'
                                        }
                                        <i className="btn-icon far fa-spinner-third fa-spin" />
                                    </button>
                                </div>
                            </div>
                            <div className="text-center fonts-secondary">
                                <Link to="/logout" className="btn-logout">
                                    Logout
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                newProfileImage ?
                    <div className="custom-modal">
                        <div className="cm-inner">
                            <ImageUploader uploadUrl={globalConfig.endpoints.users.updateAvatar.url} statusCallback={imageManagerCallback} cropOnEvent={triggerCropOnEvent} file={newProfileImage} />
                            {
                                !imageManagerResponse || !imageManagerResponse.loading ? <>
                                    <div className="row pt-3">
                                        <div className="col-6">
                                            <button onClick={e => setNewProfileImage(null)} className="btn btn-dark btn-block">Cancel</button>
                                        </div>
                                        <div className="col-6">
                                            <button onClick={e => window.dispatchEvent(new CustomEvent(triggerCropOnEvent))} className="btn btn-primary btn-block">Crop and Upload</button>
                                        </div>
                                    </div>
                                </> : <></>
                            }
                        </div>
                    </div> : ""
            }
        </>
    );
}

export default Profile;