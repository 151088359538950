import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {updateSeries, updateSeriesScript} from "../../store/reducers/entities/series/seriesSlice";
import {
    getFeatureById,
    updateDefaultAsset
} from "../../store/reducers/entities/feature/featureSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";

type Props = {
    modelType: string;
    modelId: string;
    attribute?: 'Summary' | 'Teaser';
}

const AssetText =  (props: Props): ReactElement => {

    const [script, setScript] = useState('');
    const timer = useRef<NodeJS.Timeout | null>(null);
    const dispatch = useAppDispatch();
    const series = useAppSelector(state => state.entities.series.selectedSeriesData);
    const feature = useAppSelector(state => state.entities.feature.selectedFeature);



    useEffect(() => {
        if(props.modelType === 'series' && series && props.attribute){
            if(series[props.attribute] && series[props.attribute].Text) setScript(series[props.attribute].Text)
        } else if(props.modelType === 'feature' && feature) {
            setScript(feature.DefaultAsset && feature.DefaultAsset.Text ? feature.DefaultAsset.Text : '');
        }
    },[feature, series]);

    useEffect(() => {
        if (timer) clearTimeout(timer.current!);
        if (!script || script.length <= 0) return;
        timer.current = setTimeout(() => {
            if (props.modelType === 'series' && series && props.attribute) {
                if(series[props.attribute] && series[props.attribute].Text && script === series[props.attribute].Text) return;
                dispatch(updateSeriesScript(series._id, {
                    script,
                    attribute: props.attribute
                }, 'saved!', 'Failed to save!'))
            } else if (props.modelType === 'feature' && feature) {
                if(feature.DefaultAsset && feature.DefaultAsset.Text === script) return;
                dispatch(updateDefaultAsset(feature._id, script, 'saved!', 'Failed to save caption!'))
                setTimeout(() => {
                    dispatch(getFeatureById(feature._id));
                }, 1000)
            }
        }, 400);

    }, [script]);



    return <>
        <div className="form-group floating-label-group">
						<textarea className="form-control"
                                  name="script"
                                  id="scriptText"
                                  defaultValue={script}
                                  placeholder="Script"
                                  onChange={e => setScript(e.currentTarget.value)}
                                  required
                        />
            <div className="after-control">
                <span className="focus-effect"/>
                <div className="popover bs-popover-top">
                    <div className="arrow" />
                    <div className="popover-body">
                        <span className="no-wrap">Script is required</span>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AssetText