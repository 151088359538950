import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { apiInit } from '../../middlewares/api.middleware';
import globalConfig from '../../../global.config';

export interface iUser {
  _id: any;
  FirstName: string;
  LastName: string;
  Username?: string;
  Bio?: string;
  NickName?: string;
  Location?: string;
  LanguageID?: number;
  Active: boolean;
  Pronounce?: string;
  Photo?: string | any;
  Level?: number;
  EthereumWallet?: string;
  Mobile?: string;
  Email: string;
  FB_ID?: string;
  Apple_ID?: string;
  Linkedin_ID?: string;
  Password?: string;
  Email_verified: boolean;
}

interface iAuth {
  user?: iUser | null;
  loading: boolean;
  lastFetch: number | null
}

const initialState: iAuth = {
  user: null,
  loading: false,
  lastFetch: null
}


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userDataCleared: (state) => {
      state.user = null;
    },
    userRequestInitiated: (state, action) => {
      state.loading = true;
    },
    userReceived: (state, action: PayloadAction<any>) => {
      if (action.payload && action.payload) {
        state.user = action.payload;
      }
      state.lastFetch = Date.now();
      state.loading = false;

    },
    userRequestFailed: (state, action) => {
      state.loading = false;
      if (action.payload && action.payload.statusCode === 401) state.user = null;
    }
  },
})

const { userReceived, userRequestFailed, userRequestInitiated } = authSlice.actions


export const loadUser = (messageOnDone: string, messageOnError = '') => {
  return apiInit({
    ...globalConfig.endpoints.users.getCurrentUser,
    onStart: userRequestInitiated.type,
    onSuccess: userReceived.type,
    onError: { type: userRequestFailed.type, payload: { message: messageOnError } },
    hideErrorNotification: true,
    onDone: { type: 'notification', payload: { message: messageOnDone } }
  });
}

export const updateUser = (messageOnDone: string, data: any, messageOnError = '') => apiInit({
  ...globalConfig.endpoints.users.updateCurrentUser,
  data,
  onStart: userRequestInitiated.type,
  onSuccess: userReceived.type,
  onError: { type: userRequestFailed.type, payload: { message: messageOnError } },
  onDone: { type: 'notification', payload: { message: messageOnDone } }
});


export default authSlice.reducer;

