import nProgress from 'nprogress';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { createNewSeries } from '../store/reducers/entities/series/seriesSlice';

const NewSeries = (): ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [squenced, setSequenced] = useState(false);
    const stateLoading = useAppSelector(state => state.entities.series.loading);
    const series = useAppSelector(state => state.entities.series.selectedSeriesData)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!stateLoading && loading) {
            setLoading(false)
            nProgress.done();
            navigate('/tours/' + series!._id);
        }
    }, [stateLoading])

    const createSeries = () => {
        if (title !== '' && title.length > 3) {
            nProgress.start();
            setLoading(true);
            dispatch(createNewSeries({ Name: title, Sequenced: squenced }));
        } else {
            /* todo: magic string */
            toast.error("Please enter a valid title.");
        }
    }


    return <>
        <div className="tour-title">
            
            <h1 className="mt-0 text-900 pt-3">New Tour</h1>
            <div className="form-group floating-label-group mt-0">
                <input type="text"
                    className="form-control color-dark"
                    name="Name"
                    id="Name"
                    placeholder="Name"
                    onChange={e => setTitle(e.currentTarget.value)}
                    required />
                <div className="after-control">
                    <label className="floating-label" htmlFor="Name">Tour Name</label>
                    <span className="focus-effect" />
                </div>
            </div>
            <div className="custom-checkbox-control small">
                <label htmlFor="Sequenced">
                    <input id="Sequenced" type="checkbox" name="Sequenced" defaultChecked={squenced} onChange={e => setSequenced(e.target.checked)} />
                    <span>{squenced ? <>This tour is sequenced, i.e. people should listen to it in given order.</> : <>This tour is not sequenced, i.e. people can listen to its features randomly.</>}</span>
                </label>
            </div>

            <div className="text-right">
                <button onClick={createSeries} type="submit" className="btn btn-next btn-wide btn-primary">Next</button>
            </div>
        </div>
    </>
}

export default NewSeries;