import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import PanelsNavigation from '../PanelNavigation/PanelsNavigation';

type props = {
}

const SeriesSettings = (props: props): ReactElement => {
    const panelNames = ['Center Point', 'Wiki Connect', 'Image', 'Tags'];

    
    return <div className="accordion-tab-content p-0">
        <PanelsNavigation panelNames={panelNames} modelType={'series'} />   
        <div className="shadow p-4 mt-4 rounded">
            <Outlet />
        </div>
    </div>
}

export default SeriesSettings;