import React, {ReactElement, useEffect, useState} from 'react';
import { useAppSelector } from '../../../store/hooks';
import MapBox from '../MapBox';
import NextButton from '../PanelNavigation/NextButton';
import {useNavigate, useParams} from "react-router-dom";
import AssetText from "../AssetText";
import {toast} from "react-toastify";

interface props{

}

const FeatureScript = (props: props): ReactElement => {

    const { featureId, seriesId } = useParams();
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState(false);

    const feature = useAppSelector(state => state.entities.feature);

    useEffect(() => {
        if(feature.panelsList[3].isComplete){
            setIsValid(true);
        }
    }, [feature])

    const validateScript = () => {
        if(isValid){
            navigate('/tours/'+ seriesId)
        } else {
            /* magic string */
            toast.error('Script is required to complete feature.');
        }
    }

    return <div>
        <header>
            <button onClick={validateScript} className={"btn btn-wide btn-secondary ml-auto float-right"}>Save</button>
            <h3 className="h4">Script</h3>
        </header>
        {
            featureId && featureId.length > 0 ? <AssetText modelType={'feature'} modelId={featureId} /> : ''
        }
    </div>
};

export default FeatureScript;