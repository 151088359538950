import React, {ReactElement} from 'react';
import { useAppSelector } from '../../../store/hooks';
import MapBox from '../MapBox';
import NextButton from '../PanelNavigation/NextButton';
import {useParams} from "react-router-dom";



const SeriesDrawPolygon = (): ReactElement => {

    return <div>
        <header>
            <NextButton label="Next" />
            <h3 className="h4">Draw Polygon</h3>
        </header>
        <MapBox attribute={'Geo'} modelType={'series'} mapBoxDraw={{polyline: false,
            polygon: true,
            circlemarker: false,
            circle: false,
            rectangle: false ,
            marker: false}} />
    </div>
};

export default SeriesDrawPolygon;