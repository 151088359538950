import React, {ReactElement} from 'react';
import {passwordStrengthValidator} from '../helpers/validation';
import {Link} from 'react-router-dom';

const ForgotPassword = (): ReactElement => {
    return <>
        <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
            <div className="page-form-wrap col">
                <div className="page-form">
                    <div id="general-error" />
                    <div className="multi-steps-forms">
                        <div id="step-1" className="multi-step active">
                            <form noValidate>
                                <h1 className="mt-0 mb-5 text-900 text-center">Forgot Password ?</h1>
                                <div className="form-group with-icon floating-label-group">
                                    <input className="form-control"
                                           type="email"
                                           name="email"
                                           id="username"
                                           placeholder="Email"
                                           required/>
                                    <div className="after-control">
                                        <label className="floating-label" htmlFor="username">Email</label>
                                        <span className="focus-effect" />
                                        <div className="popover bs-popover-top">
                                            <div className="arrow" />
                                            <div className="popover-body">
                                                <span className="no-wrap">Registered Email is required to Proceed</span>
                                            </div>
                                        </div>
                                        <span className="form-control-icon fal fa-envelope" />
                                    </div>

                                </div>
                                <div className="fonts-secondary">A verification code will be sent to your registered email address.</div>
                                <div className="form-group btn-wrap">
                                    <button type="submit" className="btn btn-block btn-md btn-primary text-uppercase" title="Proceed">
                                        Proceed
                                    </button>
                                </div>
                                <div className="text-center">
                                    <Link className="text-borderline float-left color-light" to="/register">
                                        Sign up
                                    </Link>
                                    <Link className="text-borderline float-right color-light" to="/">
                                        Login
                                    </Link>
                                </div>
                            </form>
                        </div>
                        <div id="step-2" className="multi-step">
                            <form data-success-message="#post-submit-note" noValidate>
                                <h1 className="mt-0 mb-5 text-900 text-center">Reset Password</h1>
                                <div className="form-group with-icon floating-label-group">
                                    <input className="form-control"
                                           type="text"
                                           id="verificationCode"
                                           name="otp"
                                           placeholder="Verification Code"
                                           data-placement="top"
                                           data-trigger="hover"
                                           required />
                                    <div className="after-control">
                                        <label className="floating-label" htmlFor="verificationCode">Verification Code</label>
                                        <span className="focus-effect" />
                                        <div className="popover bs-popover-top">
                                            <div className="arrow" />
                                            <div className="popover-body">
                                                <span className="no-wrap">Type verification code.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="form-control-icon fal fa-keyboard" />
                                </div>
                                <div className="form-group with-icon floating-label-group">
                                    <input className="form-control"
                                           id="registerPassword"
                                           type="password"
                                           name="password"
                                           data-progressbar="#password-strength-progress"
                                           placeholder="Password" required />
                                    <div className="after-control">
                                        <label className="floating-label" htmlFor="registerPassword">Password</label>
                                        <span className="focus-effect" />
                                        <div className="popover bs-popover-top">
                                            <div className="arrow" />
                                            <div className="popover-header">Password Requirements</div>
                                            <div className="popover-body">
                                                <ul className="error-dependant-list">
                                                    <li className="no-wrap error-length">Between 7-16 Characters</li>
                                                    <li className="no-wrap error-uppercase">An Upper Case Letter</li>
                                                    <li className="no-wrap error-number">A Number</li>
                                                    <li className="no-wrap error-special-characters">At Least 1 of the Following (_,#,%,*,@)</li>
                                                    <li className="no-wrap error-prohibited-characters">None of the Following ($,&,=,!)</li>
                                                </ul>
                                                <div className="progress">
                                                    <div id="password-strength-progress" className="progress-bar" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="form-control-icon lnr lnr-lock" />
                                    </div>
                                </div>
                                <div className="form-group with-icon floating-label-group">
                                    <input className="form-control popover-only-on-error"
                                           type="password"
                                           id="confirm_password"
                                           placeholder="Confirm Password"
                                           data-match="#registerPassword"
                                           required />
                                    <div className="after-control">
                                        <label className="floating-label" htmlFor="confirm_password">Confirm Password</label>
                                        <span className="focus-effect" />
                                        <div className="popover bs-popover-top"><div className="arrow" />
                                            <div className="popover-body">
                                                <span className="no-wrap error-label">Passwords do not match!</span>
                                            </div>
                                        </div>
                                        <span className="form-control-icon lnr lnr-lock" />
                                    </div>
                                </div>
                                <div className="form-group btn-wrap">
                                    <button type="submit"
                                            className="btn btn-block text-uppercase btn-md btn-primary"
                                            title="Register">
                                        Proceed
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Link id="go-to-login" className="pseudo-link" to="/">Go to Home</Link>
                </div>
            </div>
        </div>
    </>
}

export default ForgotPassword;