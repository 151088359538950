import React, { ReactElement, useEffect, useState, useRef } from 'react';

import Cropper from "react-cropper";
import 'cropperjs/dist/cropper.css';
import api from '../../helpers/api';

interface Props {
  file: File,
  uploadUrl: string,
  cropOnEvent: string,
  aspectRatio?: any,
  extraData?: Record<string, unknown>,
  statusCallback?: Function
}

const ImageUploader = (props: Props): ReactElement => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const [cropped, setCropped] = useState(null);
  const [image, setImage] = useState();

  useEffect(() => {
    window.addEventListener(props.cropOnEvent, cropAndUpload);
    makeImageFromFile();
    return () => {
      window.removeEventListener(props.cropOnEvent, cropAndUpload);
    }
  }, []);

  const makeImageFromFile = () => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(props.file);
  }

  const cropAndUpload = async () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const dataUrl = cropper.getCroppedCanvas().toDataURL(props.file.type);
    setCropped(dataUrl);
    const blob = await (await fetch(dataUrl)).blob(); 
    if(props.statusCallback){
      props.statusCallback({
        status: null,
        loading: true,
      });
    }

   try{
    const form = new FormData();
    form.append('file', blob);
    const response = await api({ url:  props.uploadUrl as string, method: 'PUT', data: form});
    if(props.statusCallback){
      props.statusCallback({
        status: {success: true, ...response.data},
        loading: false,
      });
    }
   } catch(err) {
    if(props.statusCallback){
      props.statusCallback({
        status: {success: false, error: err},
        loading: false,
      });
    }
   } 

  };


  return <>
    <div id="image-uploader">
      {
        image && !cropped ? <Cropper
          src={image}
          // Cropper.js options
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          initialAspectRatio={props.aspectRatio ? props.aspectRatio : 1}
          aspectRatio={props.aspectRatio ? props.aspectRatio : 1}
          guides={false}
          ref={cropperRef}
        /> : <>
          {
            cropped && <div className="image-being-uploaded">
              <i className="fal fa-spinner fa-spin loading-icon" />
              <img src={cropped!} alt="" />
            </div>
          }
        </>
      }
    </div>
  </>
}

export default ImageUploader;

