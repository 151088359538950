import React, {ReactElement} from 'react';
import { useAppSelector } from '../../../store/hooks';
import MapBox from '../MapBox';
import NextButton from '../PanelNavigation/NextButton';
import {useParams} from "react-router-dom";

interface props{

}

const FeatureDrawPath = (props: props): ReactElement => {

    const { featureId } = useParams();

    console.log(featureId);
    return <div>
        <header>
            <NextButton label="Next" />
            <h3 className="h4">Draw the path</h3>
        </header>
        <MapBox modelType={'feature'}  />
    </div>
};

export default FeatureDrawPath;