import React, {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';

const Logout = (): ReactElement => {
    const navigate = useNavigate();

    const userOut = () => {
        Cookies.remove('access_token');        
		navigate('/');
    }

    return <>
		<div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
			<div className="page-form-wrap col-lg-4 col-md-8 col-8">
				<div className="page-form text-center text-uppercase">
					<i className="page-icon far fa-power-off color-primary" />
					<h1 className="text-900 mb-5">Logout?</h1>
					<div className="row">
						<div className="col-12 col-sm-6">
							<button onClick={userOut} className="btn btn-primary btn-block mb-1">Yes</button>
						</div>
						<div className="col-12 col-sm-6">
							<button onClick={e => navigate(-1)} className="btn btn-secondary btn-block mb-1">Cancel</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export default Logout;