import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import apiMiddleware from './middlewares/api.middleware';
import notificationMiddleware from './middlewares/notification.middleware';
import { loadState } from './persistState';
import authSlice from './reducers/auth/authSlice';
import entities from './reducers/entities/index';

export const store = configureStore({
  preloadedState: loadState(),
  reducer: {
    //entities
      // -series reducer
      // -feature reducer
      // -asset reducer
    //auth
    //ui
    entities: entities,
    auth: authSlice,
    // app reducer
    
  },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), notificationMiddleware, apiMiddleware],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
