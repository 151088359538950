
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import TagsManager from '../TagsManager';



const SeriesTags = (): ReactElement => {

    const navigate = useNavigate();
    const series = useAppSelector(state => state.entities.series.selectedSeriesData)
   
	if(!series) <></>;
 

    return <>
        <button onClick={e => navigate('/tours/'+ series!._id)} className={"btn btn-wide btn-secondary ml-auto float-right"}>Save Settings</button>
        <h3 className="h4 mt-2">Tags</h3>
		<TagsManager modelId={series!._id} modelType={'series'} tags={series!.Tag ? series!.Tag : ''} eras={series!.Era ? series!.Era : ''}  />
    </>
};

export default SeriesTags;