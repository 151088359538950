import axios, { AxiosError, Method } from "axios";
import Cookies from 'js-cookie'
import { ThunkMiddleware } from "redux-thunk";

import { createAction } from "@reduxjs/toolkit";
import { notificationAction } from "./notification.middleware";

export const apiInit = createAction<iApiPayload>("api/init");
export const apiSuccess = createAction<any>("api/success");
export const apiError = createAction<any>("api/error");


interface iCustomAction {
    type: string;
    payload: Record<string, unknown>;
}

interface iApiPayload {
    onStart?: string,
    onDone?: iCustomAction,
    url: string;
    method: Method;
    data?: any;
    onSuccess: string,
    onError?: iCustomAction,
    hideErrorNotification?: boolean
}


export interface iApiCall {
    type: string;
    payload: iApiPayload;
}

const apiMiddleware: ThunkMiddleware = ({ dispatch }) => (next) => async (action) => {
    if (action.type !== apiInit.type) return next(action);

    const { url, method, data, onError, onSuccess, onStart, onDone, hideErrorNotification }: iApiPayload = action.payload;

    if (onStart) {
        dispatch({ type: onStart });
    }
    next(action);
    try {
        const headers = { Authorization: `Bearer ${Cookies.get('access_token')}` };
        const response = await axios({ url, method, data, headers });
        dispatch(apiSuccess(response.data));
        if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
        if (onDone) dispatch({ type: onDone.type, payload: onDone.payload })
    } catch (error: any) {
        //general error
        if (!hideErrorNotification) {
            let msg = error.message;
            /* todo: magic string */
            if (error.response.status === 401) msg = "Permission denied.";
            dispatch(notificationAction({
                type: 'error',
                message: msg
            }));
        }
        if (onError) {
            dispatch({ type: onError.type, payload: onError.payload })
        }
    }
}
export default apiMiddleware;