

import React, { ReactElement, useEffect, useState } from "react";
import L from "mapbox.js";
import "leaflet-draw";
import "leaflet-geodesy"
import "mapbox.js/dist/mapbox.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { useAppSelector } from "../../store/hooks";
import { updateSeries } from "../../store/reducers/entities/series/seriesSlice";
import { useDispatch } from "react-redux";
import {updateFeature} from "../../store/reducers/entities/feature/featureSlice";


type Props = {
    modelType: 'feature' | 'series';
    attribute? : 'Initial_geo' | 'Geo',
    mapBoxDraw?: Record<string, unknown>
}




const MapBox = (props: Props): ReactElement => {

    const dispatch = useDispatch();
    const series = useAppSelector(state => state.entities.series.selectedSeriesData);
    const feature = useAppSelector(state => state.entities.feature.selectedFeature);
    const [map, setMap] = useState<any>();
    const [editableLayers, setEditableLayers] = useState<any>();
    const [mapSettingCompleted, setMapSettingCompleted] = useState(false);
    const [zoomlvl, setZoomlvl] = useState(props.modelType === 'series' ? series?.MapZoom : feature?.MapZoom);
    const [latitude, setLatitude] = useState(props.modelType === 'series' ? series?.Lat : feature?.Lat)
    const [longitude, setLongitude] = useState(props.modelType === 'series' ? series?.Lng : feature?.Lng)
    const [geo, setGeo] = useState(props.modelType === 'series' ? (series ? series[props.attribute!] : null) : feature?.Geo);
    const [updated, setUpdated] = useState(false);



    const defaultDrawOptions = {
        polyline: true,
        polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
                color: '#e1e100', // Color the shape will turn when intersects
                message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
            }
        },
        circlemarker: false,
        circle: false, // Turns off this drawing tool
        rectangle: false,
        marker: false
    };



    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (!mapSettingCompleted && map) {
            setMapSettingCompleted(true);
            configureMap();
        }
    }, [map]);

    useEffect(() => {
        if(!updated) return;
        setUpdated(false)
        if(props.modelType === 'series'){
            let newData: any = {
                MapZoom: zoomlvl,
                Lat: latitude,
                Lng: longitude
            }
            if(props.attribute) newData[props.attribute] = geo;
            else newData.Initial_geo = geo;
            dispatch(updateSeries(series!._id, newData, 'Saved!', 'Failed to update!'));
        } else if(props.modelType === 'feature'){
            dispatch(updateFeature(feature!._id, {
                Geo: geo,
                MapZoom: zoomlvl,
                Lat: latitude,
                Lng: longitude
            }, 'Saved!', 'Failed to update!'));
        }
    }, [geo]);



    const configureMap = async () => {

        const options = {
            position: 'topleft',
            minZoom: 0,
            maxZoom: 25,
            draw: props.mapBoxDraw ? props.mapBoxDraw : defaultDrawOptions,
            edit: {
                featureGroup: editableLayers, //REQUIRED!!
                remove: true,
                edit: true
            }
        };

        const parent = document.getElementById("geo-map-wrap")!;
        await addGeoJson();
        map.addLayer(editableLayers);


        const drawControl = new L.Control.Draw(options);
        map.addControl(drawControl);
        map.on('draw:created', showPolygonArea);
        map.on('draw:edited', showPolygonAreaEdited);
        map.on('draw:updated', showPolygonArea);
        map.on('draw:deleted', showPolygonAreaRemoved);

        const defaultView = L.tileLayer('mapbox://styles/mapbox/streets-v11', {
            maxNativeZoom: 19,
            maxZoom: 25
        }).addTo(map);

        const mapboxStreets = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            maxNativeZoom: 19,
            maxZoom: 25
        }).addTo(map);

        const mapboxsat = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            maxNativeZoom: 19,
            maxZoom: 25
        }).addTo(map);

        const basemap = { 'Basic': defaultView, 'Streets': mapboxStreets, 'Aerials': mapboxsat };
        L.control.layers(basemap).addTo(map); // Layers Control just to switch between the 2 basemaps.

        (parent.querySelectorAll('input[name="leaflet-base-layers"]')[0] as any).click();

        /*hide guides on click of any draw tool */

        const leafletControls = parent.querySelectorAll('.leaflet-control-container .leaflet-top.leaflet-left a');
        leafletControls.forEach(ele => {
            ele.addEventListener('click', (e) => {
                parent.classList.remove('label-overlay-active');
            });
        });


    }

    const init = async () => {
        const mapId = "geo-map";
        let initZoom = zoomlvl ? zoomlvl : 6,
            initLat = latitude ? latitude : '37.8',
            initLon = longitude ? longitude : '-96';
        L.mapbox.accessToken = 'pk.eyJ1Ijoid3d3bWFzdGVyMSIsImEiOiJjazZmbmxhYngwYjQxM2xtdDdwMjJzYjdnIn0._QtAdUTg9NtC9_R8Caq6Ng';
        const _map = L.mapbox.map(mapId).setView([initLat, initLon], initZoom)
            .addLayer(L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11'))
            .addControl(L.mapbox.geocoderControl('mapbox.places', { keepOpen: false }));

        setMap(_map);
        setEditableLayers(new L.FeatureGroup());
    }


    const addGeoJson = async () => {
        if (geo && geo.length > 10) {
            const addToMap = L.geoJson(JSON.parse(geo as string)).addTo(map);
            const newLayer = addToMap.getLayers()[0];
            editableLayers.addLayer(newLayer);
            let mapZoom = zoomlvl ? zoomlvl : 6;
            map!.setView([latitude, longitude], mapZoom);
            map!.invalidateSize();
        }
    }


    const showPolygonAreaRemoved = async (e: any) => {
        setUpdated(true);
        setZoomlvl(map.getZoom());
        setLatitude(map.getCenter()["lat"]);
        setLongitude(map.getCenter()["lng"]);
        setGeo(JSON.stringify(e.layer ? e.layer.toGeoJSON() : ''));
    }

    const showPolygonAreaEdited = (e: any) => {
        e.layers.eachLayer((layer: any) => { showPolygonArea({ layer: layer }); });
    }

    const showPolygonArea = async (e: any) => {
        editableLayers.clearLayers();
        editableLayers.addLayer(e.layer);
        setUpdated(true);
        setZoomlvl(map.getZoom());
        setLatitude(map.getCenter()["lat"]);
        setLongitude(map.getCenter()["lng"]);
        setGeo(JSON.stringify(e.layer ? e.layer.toGeoJSON() : ''));
    }



    return <>
        <div id="geo-map-wrap" className={geo && geo.length > 10 ? 'no-labels' : 'label-overlay-active'}>
            <div id="geo-map" className="map-canvas" />
        </div>
    </>
}

export default MapBox