import React, {ReactElement} from 'react';
import globalConfig from '../../../global.config';

type Props = {
    label: string;
    classList?: string;
}


const NextButton =  (props: Props): ReactElement => {

    const goToNextStep = () => {
        const event = new CustomEvent(globalConfig.events.stepsNavigation.next);
        window.dispatchEvent(event);
    }

    return <button onClick={e => {
        e.stopPropagation();
        goToNextStep();
    }} className={props.classList ? props.classList : "btn btn-wide btn-secondary ml-auto float-right"}>{props.label}</button>
}

export default NextButton