import nProgress from 'nprogress';
import React, { ReactElement, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {getMySeriesById, updateSeries} from '../store/reducers/entities/series/seriesSlice';
import UpdateName from './partials/UpdateName';

const EditSeries = (): ReactElement => {
    const dispatch = useAppDispatch();
    const loadingState = useAppSelector(state => state.entities.series.loading);
    const series = useAppSelector(state => state.entities.series.selectedSeriesData);
    const seriesPanels = useAppSelector(state => state.entities.series.panelsList);
    const seriesFinalPanels = useAppSelector(state => state.entities.series.panelsListFinal);
    const [canAddFeatures, setCanAddFeatures] = useState(false);
    const [canFinishSeries, setCanFinishSeries] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { seriesId } = useParams();

    useEffect(() => {
        nProgress.start();
        console.log('series id: ', seriesId);
        dispatch(getMySeriesById(seriesId!));
    }, [])

    useEffect(() => {
        if (seriesPanels[0].isComplete && seriesPanels[2].isComplete) setCanAddFeatures(true);
        else setCanAddFeatures(false);
    }, [seriesPanels]);

    useEffect(() => {
        if (seriesFinalPanels[0].isComplete && seriesFinalPanels[1].isComplete && seriesFinalPanels[2].isComplete) {
            setCanFinishSeries(!!(series && series.AllFeaturesComplete));
        }
        else setCanFinishSeries(false);
    }, [seriesFinalPanels]);

    useEffect(() => {
        if (!loadingState) {
            nProgress.done();
        }
    }, [loadingState])


    const startFinalizing = () => {
        dispatch(updateSeries(series!._id!, {StartedFinalizing: true}, "Updated!", "Error!"))
        navigate('finalize');
    }
    const flagForReview = () => {
        dispatch(updateSeries(series!._id!, {Status: 'review'}, "Updated!", "Error!"))
        setTimeout(() => {
            navigate('/tours');
        }, 1000);
    }

    if(!series) return <><i className="fas fa-spin fa-spinner mr-2" />Loading...</>
    return <>

        <div className="progress tour-progress-status">
            <div className="progress-bar" style={{width: series?.CompletionPercentage + '%'}} role="progressbar">
                {series?.CompletionPercentage + '%'}
            </div>
        </div>
        <div className="position-relative">
            <span className="h2 text-900">{series?.Name}</span>
            <UpdateName modelType="series" modelId={seriesId!} />
        </div>
        <div id="new-tour-accordions" className="accordion-tabs tour-accordions mt-3">
            <div className={location.pathname.includes('/setting') ? "accordion-tab active" : "accordion-tab"}>
                <div className="accordion-tab-trigger">
                    <button onClick={e => { location.pathname.includes('/setting') ? navigate('') : navigate('setting') }}>
                        Tour Settings
                        <span className="badge badge-primary float-right mr-5">{series!.SettingCompletionPercentage}%</span>
                    </button>
                </div>
                {
                    location.pathname.includes('/setting') && <Outlet />
                }
            </div>

            {
                series && series.Features && !!series.Features.length && series.Features.map(feature => {
                    return <div key={'feature-' + feature._id} className={location.pathname.includes('/feature/' + feature._id) ? "accordion-tab active" : "accordion-tab"}>
                        <div className="accordion-tab-trigger">
                            <UpdateName currentName={feature.Name} modelType="feature" modelId={feature._id} />
                            <button onClick={e => { location.pathname.includes('/feature/' + feature._id) ? navigate('') : navigate('feature/' + feature._id) }}>
                                {feature.Name}
                                <span className="badge badge-primary float-right mr-5">{feature.CompletionPercentage}%</span>
                            </button>
                        </div>
                        {
                            location.pathname.includes('/feature/' + feature._id) && <Outlet />
                        }
                    </div>
                })
            }

            {
                location.pathname.includes('/new-feature') && <Outlet />
            }

            {
                series && series.StartedFinalizing ?
                <div className={location.pathname.includes('/finalize') ? "accordion-tab active" : "accordion-tab"}>
                    <div className="accordion-tab-trigger">
                        <button onClick={e => {
                            location.pathname.includes('/finalize') ? navigate('') : navigate('finalize')
                        }}>
                            Finalize Tour
                            <span className="badge badge-primary float-right mr-5">{series!.FinalizationCompletionPercentage}%</span>
                        </button>
                    </div>
                    {
                        location.pathname.includes('/finalize') && <Outlet/>
                    }
                </div> : location.pathname.includes('/finalize') && <Outlet/>
            }
            <div className="text-right">
                {canAddFeatures && !location.pathname.includes('/new-feature') && <button onClick={e => navigate('new-feature')} className="btn btn-outline-primary mr-1"><i className="far fa-plus" /> Add Feature</button>}
                {
                    series && !series.StartedFinalizing && series.AllFeaturesComplete && series.Features.length > 0 ? <button onClick={startFinalizing} className="btn btn-success btn-min-width"><i className="far fa-plus"></i> Finalize Tour</button> : ''
                }
                {
                    canFinishSeries ? <button onClick={ flagForReview } className="btn btn-success btn-min-width"><i className="far fa-paper-plane"></i> Submit For Review</button> : ''
                }
            </div>
        </div>
    </>
}

export default EditSeries;
