import React, { ReactElement } from 'react';
import globalConfig from '../../../global.config';
import { useAppSelector } from '../../../store/hooks';
import NextButton from '../PanelNavigation/NextButton';
import TourImageManager from '../TourImageManager';


const SeriesImage = (): ReactElement => {

    const series = useAppSelector(state => state.entities.series.selectedSeriesData)

    if (!series) return <></>
    return <>
        <NextButton label="Next" />
        <TourImageManager
            modelId={series._id}
            modelType={'series'}
            uploadingUrl={globalConfig.endpoints.series.updateBoxImg.url + '/' + series._id} />
    </>
}

export default SeriesImage