import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ImageUploader from './ImageUploader';
import { nanoid } from 'nanoid';
import globalConfig from '../../global.config';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import { getMySeriesById, updateCaption } from '../../store/reducers/entities/series/seriesSlice';
import {getFeatureById, updateCaption as updateFeatureCaption} from "../../store/reducers/entities/feature/featureSlice";


interface props {
    modelType: 'series' | 'feature';
    modelId: string;
    uploadingUrl: string;
}

// data={feature}


// data={series}

const TourImageManager = (props: props): ReactElement => {
    const timer = useRef<NodeJS.Timeout | null>(null);
    const dispatch = useAppDispatch();
    const feature = useAppSelector(state => state.entities.feature.selectedFeature)
    const series = useAppSelector(state => state.entities.series.selectedSeriesData)
    const [fileToUpload, setFiletToUpload] = useState<File | null>(null);
    const triggerCropOnEvent = 'cancel_image_update';
    const [imageResponse, setImageResponse] = useState<Record<string, unknown>>();
    const [letsChange, setLetsChange] = useState(false);
    const [caption, setCaption] = useState('');
    const [currentCaption, setCurrentCaption] = useState('');
    const [randomId, setRandomId] = useState(nanoid(6));
    const [wikiImages, setWikiImages] = useState<any>(null);
    const [wikiImagesRes, setWikiImagesRes] = useState<any>(null);
    const [alreadyHasImage, setAlreadyHasImage] = useState(false);
    const [currentImage, setCurrentImage] = useState<any>(null);
    const [wikiID, setWikiID] = useState<any>(null);

    const imageUploadCallback = (data: any) => {
        setImageResponse(data);
    }

    useEffect(() => {
        if(props.modelType === 'series' && series) {
            const cap = series.BoxImg && series.BoxImg._id && series.BoxImg.AltText ? series.BoxImg.AltText : '';
            setCaption(cap);
            setCurrentCaption(cap);
            setAlreadyHasImage(!!(series.BoxImg))
            setCurrentImage(series.BoxImg)
            setWikiID(series.WikiID)
        } else if(props.modelType === 'feature' && feature) {
            const cap = feature.BoxImg && feature.BoxImg._id && feature.BoxImg.AltText ? feature.BoxImg.AltText : '';
            setCaption(cap);
            setCurrentCaption(cap);
            setAlreadyHasImage(!!(feature.BoxImg))
            setCurrentImage(feature.BoxImg)
            setWikiID(feature.WikiID)
        }
    }, [series, feature]);
    useEffect(() => {
        getWikiImagesWithUrl()
    }, [wikiID])

    useEffect(() => {
        if (timer) clearTimeout(timer.current!);
        if ((caption.trim()).length <= 0) return;
        timer.current = setTimeout(() => {
            if (props.modelType === 'series' && series && series.BoxImg && series.BoxImg._id && caption !== currentCaption) {
                dispatch(updateCaption(series.BoxImg._id, caption.trim(), 'saved!', 'Failed to save caption!'))
            } else if (props.modelType === 'feature' && feature && feature.BoxImg && feature.BoxImg._id && caption !== currentCaption) {
                dispatch(updateFeatureCaption(feature.BoxImg._id, caption.trim(), 'saved!', 'Failed to save caption!'))
            }
        }, 1000)
    }, [caption])

    useEffect(() => {
        if (imageResponse && !imageResponse.loading && imageResponse.status) {
            setFiletToUpload(null);
            setLetsChange(false);
            if (props.modelType === 'series') dispatch(getMySeriesById(props.modelId));
            if (props.modelType === 'feature') dispatch(getFeatureById(props.modelId));
            setRandomId(nanoid(6));
        }
    }, [imageResponse]);

    useEffect(() => {
        fetchWikiImageInfo();
    }, [wikiImagesRes])



    const onFileBrowse = (e: any) => {
        const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg']
        const file = e.currentTarget.files[0];
        if (allowedTypes.includes(file.type)) {
            setFiletToUpload(file);
        } else {
            /* todo: magic string */
            toast.error('Please select png or jpg file');
        }
    }

    const usingWikiImage = (img: any) => {
        const jpgTypes = ['jpg', 'JPG', 'jpeg', 'JPEG'];
        const imageType = jpgTypes.indexOf(img.url.split('.').pop()) !== -1 ? 'image/jpeg' : 'image/' + img.url.split('.').pop();
        fetch(img.url).then(res => res.blob()).then(blob => {
            const file = new File([blob], img.title, { type: blob.type })
            setFiletToUpload(file);
        });
    }

    const getWikiImagesWithUrl = async () => {
        if (wikiID) {
            const wikiQueryUrl = globalConfig.externalEndpoints.wikipedia.getPageImages + wikiID;
            fetch(wikiQueryUrl).then(response => response.json()).then(async data => {
                setWikiImagesRes(data['query']['pages'][wikiID]['images']);
            }).catch((error) => {
                console.error('Error:', error);
            });
        }
    }

    const fetchWikiImageInfo = async () => {
        if (!wikiImagesRes) return;
        let images: any = [];
        for (const img of wikiImagesRes) {
            const excludeImages = ['ui icon', 'logo', 'icon.svg', 'map of', 'map.svg', 'pog.svg', 'flag of'];
            let isValidImage = true;
            for (let exclude of excludeImages) {
                if ((img.title.toLowerCase()).includes(exclude)) {
                    isValidImage = false;
                }
            }
            if (isValidImage) {
                const queryUrl = globalConfig.externalEndpoints.wikipedia.getImageInfo + img.title;
                try {
                    const res = await fetch(queryUrl);
                    const data = await res.json();
                    const url: any = (Object.entries(data['query']['pages']) as any)[0][1]['imageinfo'][0]['url'];
                    images.push({
                        title: img.title,
                        url
                    })
                } catch (e) {
                    // console.log('wiki image error')
                }
            }
        }
        setWikiImages([...images]);
    }

    return <>
        <h3 className="h4">Image</h3>
        {
            !letsChange && alreadyHasImage &&
            <>
                <div className="row">
                    <div className="col-12 col-md-5">
                        <div className="tour-image-wrap">
                            <button onClick={e => setLetsChange(true)} className="btn color-primary"><i className="fal fa-arrow-left"></i> Change Image</button>
                            {
                                currentImage && currentImage.MimeType ? <img id="profile_picture" alt="profile" src={globalConfig.s3MediaBucket + currentImage._id + '.' + currentImage.MimeType.split('/')[1] + '?v=' + randomId} /> : ''
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-7 text-left">
                        <h6 className="mb-3 mt-1">Caption</h6>
                        <p>Use this space to describe the image, add copyrights,<br /> give credit or specify the source</p>
                        <div className="form-group floating-label-group">
                            <input value={caption} onChange={e => setCaption(e.currentTarget.value)} className="form-control" type="text" name="BoxImgCap" id="BoxImgCap" placeholder="Image caption" data-placement="top" data-trigger="hover" />
                            <div className="after-control"><label className="floating-label" htmlFor="BoxImgCap">Image Caption</label>
                                <div className="popover bs-popover-top"><div className="arrow"></div>
                                    <div className="popover-body"><span className="no-wrap">Use this space to describe the image, add copyrights,<br /> give credit or specify the source</span>
                                    </div>
                                </div>
                                <span className="focus-effect"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }


        {!fileToUpload && (!alreadyHasImage || letsChange) && <>
            {wikiImages && wikiImages.length ? <p className="d-inline-block animated fadeInUp position-relative">
                Select one of the following images or</p> : ''}
            <div className="d-inline-block file-selector-wrap">
                <input id="uploadNewImage" name="uploadNewImage" type="file" onChange={onFileBrowse} accept=".jpg, .jpeg, .png" />
                <label htmlFor="uploadNewImage" className="btn text-400 color-primary">
                    <i className="fas fa-cloud-upload"></i> Upload a new image
                </label>
            </div>

            <div className="row">
                {wikiImages && wikiImages.map((img: any, i: number) => {
                    return (
                        <div key={'wiki-image-' + i} className="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                            <div className="form-group custom-image-selector" style={{ backgroundImage: `url('${img.url}')` }}>
                                <img src={img.url} alt={img.title} />
                                <label htmlFor={"wiki-page-" + i}>{img.title.replace('File:', '')}</label>
                                <input type="radio"
                                    onChange={e => usingWikiImage(img)}
                                    value={i}
                                    className="form-control"
                                    name="selectedImage"
                                    title={img.title.replace('File:', '')}
                                    required
                                />
                                <div className="after-control">
                                    <span className="focus-effect" />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            {letsChange && <>
                <br />
                <button className="btn btn-success" onClick={e => setLetsChange(false)}><i className="fal fa-long-arrow-left" />  back to current image</button>
            </>}
        </>}

        <div className="row">
            <div className="col-12 col-md-6 offset-md-3">
                {
                    fileToUpload && (!imageResponse || !imageResponse.loading) ? <>
                        <div className="row pt-3 mb-3 narrow-uploading-container">
                            <div className="col-6">
                                <button onClick={e => setFiletToUpload(null)} className="btn btn-dark btn-block">Cancel</button>
                            </div>
                            <div className="col-6">
                                <button onClick={e => window.dispatchEvent(new CustomEvent(triggerCropOnEvent))} className="btn btn-primary btn-block">Crop and Upload</button>
                            </div>
                        </div>
                    </> : <></>
                }
                {
                    fileToUpload && <ImageUploader aspectRatio={16 / 9} uploadUrl={props.uploadingUrl} statusCallback={imageUploadCallback} cropOnEvent={triggerCropOnEvent} file={fileToUpload} />
                }
            </div>
        </div>

    </>
};

export default TourImageManager;