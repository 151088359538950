import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import { iTabPanel } from "..";
import globalConfig from "../../../../global.config";
import { apiInit } from "../../../middlewares/api.middleware";
import { iFeature } from "../feature/featureSlice";


interface iSeriesExcerpt {
    _id: string;
    Name: string;
    Status: string;
    Active: boolean;
}

interface iSeriesScript{
    attribute: 'Summary' | 'Teaser';
    script: string;
}

interface iSeries {
    _id: string;
    Status: string;
    Name: string;
    JSON?: string;
    Active: boolean;
    Summary?: string;
    Teaser?: string | any;
    Img?: string | any;
    Era?: string;
    Tag?: string;
    Lng?: number;
    Lat?: number;
    Initial_geo?: string;
    Elv?: number;
    Mph?: number;
    Deg?: number;
    Geo?: string;
    MapZoom?: number;
    Exp?: Date;
    WikiID?: number;
    FoundRelatedWikiArticles: boolean;
    GoogID?: string;
    BoxImg?: string | any;
    BoxImgCap?: string | any;
    Sequenced: number;
    User: string;
    /*only relevant to the editor*/
    StartedFinalizing: boolean;
    Features: iFeature[],
    AllFeaturesComplete: boolean,
    SettingCompletionPercentage: number,
    FinalizationCompletionPercentage: number,
    CompletionPercentage: number
}


interface iSeriesInitiaState {
    list: null | iSeriesExcerpt[];
    loading: boolean;
    selectedSeriesId: null | string;
    selectedSeriesData: null | iSeries,
    panelsList: Array<iTabPanel>;
    panelsListFinal: Array<iTabPanel>;
}

const getDefaultPanels = (): iTabPanel[] => [
    { isComplete: true, url: 'center-point' },
    { isComplete: false, url: 'wiki-connect' },
    { isComplete: false, url: 'image' },
    { isComplete: false, url: 'tags' },
]

const getFinalizedPanels = (): iTabPanel[] => [
    { isComplete: true, url: 'polygon' },
    { isComplete: false, url: 'summary' },
    { isComplete: false, url: 'teaser' },
]


const initialState: iSeriesInitiaState = {
    loading: false,
    list: null,
    selectedSeriesId: null,
    selectedSeriesData: null,
    panelsList: [
        { isComplete: true, url: 'center-point' },
        { isComplete: false, url: 'wiki-connect' },
        { isComplete: false, url: 'image' },
        { isComplete: false, url: 'tags' },
    ],
    panelsListFinal: [
        { isComplete: true, url: 'polygon' },
        { isComplete: false, url: 'summary' },
        { isComplete: false, url: 'teaser' },
    ]
}


const seriesSlice = createSlice({
    name: 'series',
    initialState,
    reducers: {
        seriesReceived: (state, action: PayloadAction<any>) => {
            const payload = action.payload;
            if (payload._id) {
                state.selectedSeriesData = payload;
            }
            state.loading = false;
            if (!state.panelsList) state.panelsList = getDefaultPanels();
            if (payload.Initial_geo && payload.Initial_geo.length > 50) {
                state.panelsList[0].isComplete = true;
            }
            state.panelsList[1].isComplete = !payload.FoundRelatedWikiArticles || (payload.FoundRelatedWikiArticles && payload.WikiID);
            state.panelsList[2].isComplete = !!(payload.BoxImg && payload.BoxImg._id && payload.BoxImg.AltText && payload.BoxImg.AltText.length > 0);
            state.panelsList[3].isComplete = payload.Tag && payload.Tag.length > 0;

            /*finalized panels*/
            if (!state.panelsListFinal) state.panelsListFinal = getFinalizedPanels();
            state.panelsListFinal[0].isComplete = payload.Geo && payload.Geo.length > 50;
            state.panelsListFinal[1].isComplete = payload.Summary && payload.Summary._id.length > 0;
            state.panelsListFinal[2].isComplete = payload.Teaser && payload.Teaser._id.length > 0;

        },
        listReceived: (state, action: PayloadAction<any>) => {
            if (action.payload && action.payload.length) {
                state.list = action.payload;
            }
            state.loading = false;
        },
        requestCompleted: (state, action: PayloadAction<any>) => {
            state.loading = false;
        },
        requestInitiated: (state, action: PayloadAction<any>) => {
            state.loading = true;
        },
        requestFailed: (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (action.payload && action.payload.messsage) {
                toast.error(action.payload.message, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }
});


const { seriesReceived, listReceived, requestFailed, requestInitiated, requestCompleted } = seriesSlice.actions;


export const getMySeriesList = (messageOnError = '') => {
    return apiInit({
        ...globalConfig.endpoints.series.getMySeries,
        onStart: requestInitiated.type,
        onSuccess: listReceived.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        hideErrorNotification: true
    });
}

export const getMySeriesById = (seriesId: string, messageOnError = '') => {
    console.log(seriesId, messageOnError);
    return apiInit({
        url: globalConfig.endpoints.series.getSeriesById.url + '/' + seriesId,
        method: globalConfig.endpoints.series.getSeriesById.method,
        onStart: requestInitiated.type,
        onSuccess: seriesReceived.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        hideErrorNotification: true
    });
}

type newSeries = {
    Name: string,
    Sequenced: boolean
}

export const createNewSeries = (data: newSeries, messageOnError = '') => {
    return apiInit({
        ...globalConfig.endpoints.series.createNewSeries,
        data,
        onStart: requestInitiated.type,
        onSuccess: seriesReceived.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        hideErrorNotification: true
    });

}

export const updateSeries = (seriesId: string, data: Record<string, unknown>, successMessage: string, messageOnError = '') => {
    return apiInit({
        method: globalConfig.endpoints.series.updateSeries.method,
        url: globalConfig.endpoints.series.updateSeries.url + '/' + seriesId,
        data,
        onStart: requestInitiated.type,
        onSuccess: seriesReceived.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        onDone: { type: 'notification', payload: { message: successMessage } }
    });
};


export const updateCaption = (assetId: string, newCaption: string, successMessage: string, messageOnError = '') => {
    return apiInit({
        method: globalConfig.endpoints.asset.updateAssetById.method,
        url: globalConfig.endpoints.asset.updateAssetById.url + '/' + assetId,
        data: {AltText: newCaption},
        onStart: requestInitiated.type,
        onSuccess: requestCompleted.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        onDone: { type: 'notification', payload: { message: successMessage } }
    });
};


export const updateSeriesScript = (seriesId: string, data: iSeriesScript, successMessage: string, messageOnError = '') => {
    return apiInit({
        method: globalConfig.endpoints.feature.updateScript.method,
        url: globalConfig.endpoints.series.updateScript.url + '/' + seriesId,
        data,
        onStart: requestInitiated.type,
        onSuccess: seriesReceived.type,
        onError: { type: requestFailed.type, payload: { message: messageOnError } },
        onDone: { type: 'notification', payload: { message: successMessage } }
    });
};


export default seriesSlice.reducer;
