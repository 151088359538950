import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';

import './sass/style.scss';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Register from './pages/Register';
import CookiesPolicy from './pages/CookiesPolicy';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import ProtectedRoute from './pages/partials/ProtectedRoute';
import Logout from './pages/Logout';
import NewSeries from './pages/NewSeries';
import MyTours from './pages/MyTours';
import SeriesList from './pages/ListSeries';
import EditSeries from './pages/EditSeries';
import SeriesSettings from './pages/partials/Series/SeriesSetting';
import SeriesCenterPoint from './pages/partials/Series/CenterPoint';
import SeriesWiki from './pages/partials/Series/SeriesWiki';
import SeriesTags from './pages/partials/Series/SeriesTags';
import NotFound from './pages/NotFound';
import TourImageManager from './pages/partials/TourImageManager';
import SeriesImage from './pages/partials/Series/SeriesImage';
import CreateNewFeature from './pages/partials/Feature/CreateNewFeature';
import FeatureDetail from './pages/partials/Feature/FeatureDetail';
import FeatureImage from "./pages/partials/Feature/FeatureImage";
import FeatureTags from "./pages/partials/Feature/FeatureTags";
import FeatureScript from "./pages/partials/Feature/FeatureScript";
import SeriesFinalize from "./pages/partials/Series/SeriesFinalize";
import SeriesSummary from "./pages/partials/Series/SeriesSummary";
import SeriesTeaser from "./pages/partials/Series/SeriesTeaser";
import FeatureDrawPath from "./pages/partials/Feature/FeatureDrawPath";
import SeriesDrawPolygon from "./pages/partials/Series/SeriesDrawPolygon";


function App() {
  return (
    <>
      <main className="page gradient-bg">
        <ToastContainer />
        <div className="container">
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/tours" element={<MyTours />}>
                <Route path="" element={<SeriesList />}></Route>
                <Route path="new" element={<NewSeries />}></Route>
                <Route path=":seriesId" element={<EditSeries />}>
                  <Route path="new-feature" element={<CreateNewFeature />} />
                  <Route path="setting" element={<SeriesSettings />}>
                    <Route path="center-point" element={<SeriesCenterPoint />} />
                    <Route path="image" element={ <SeriesImage />} />
                    <Route path="wiki-connect" element={<SeriesWiki />} />
                    <Route path="tags" element={<SeriesTags />} />
                  </Route>
                  <Route path="feature/:featureId" element={<FeatureDetail />} >
                    <Route path="draw-the-path" element={<FeatureDrawPath />} />
                    <Route path="image" element={<FeatureImage />} />
                    <Route path="tags" element={<FeatureTags />} />
                    <Route path="script" element={<FeatureScript />} />
                  </Route>
                  <Route path="finalize" element={<SeriesFinalize />} >
                    <Route path="polygon" element={<SeriesDrawPolygon />} />
                    <Route path="summary" element={<SeriesSummary />} />
                    <Route path="teaser" element={<SeriesTeaser />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </div>
      </main>
    </>
  );
}

export default App;
